import { RefAPILocation } from '../../shared/Models/RefAPILocation';

export class CarrierTruckPostingSearchParameters {
  Origin: RefAPILocation;
  Destination: RefAPILocation;
  ODH: number;
  DDH: number;
  TruckTypeCode: string;
  StartDate: Date;
  EndDate: Date;

  constructor() {
    this.Origin = null;
    this.Destination = null;
    this.ODH = null;
    this.DDH = null;
    this.TruckTypeCode = null;
    this.StartDate = null;
    this.EndDate = null;
  }
}

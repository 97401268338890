import { CarrierIdentityDC } from '../dto/CarrierIdentityDC';
import { RefIdentityDC } from '../dto/RefIdentityDC';
import { CarrierIdentities, RefIdentity } from './CarrierIdentity';
import { CarrierName } from './CarrierName';

export class CarrierIdentityState {
  loading: boolean;
  loadingError: string;
  updateLoading: false;
  updateError: string;
  carrierName: CarrierName;
  refIdentifier: RefIdentity[];
  refSystem: RefIdentity[];
  refFinancial: RefIdentity[];
  refDocket: RefIdentity[];
  refState: RefIdentity[];
  refCanadian: RefIdentity[];
  identities: CarrierIdentities;

  public constructor(
    identities: CarrierIdentityDC[],
    refs: RefIdentityDC[],
    carrierName: CarrierName,
  ) {
    this.refIdentifier = CarrierIdentityState.createRefListByGroup(
      'Identifier',
      refs,
    );
    this.refSystem = CarrierIdentityState.createRefListByGroup(
      'System ID',
      refs,
    );
    this.refFinancial = CarrierIdentityState.createRefListByGroup(
      'Financial',
      refs,
    );
    this.refDocket = CarrierIdentityState.createRefListByGroup(
      'Docket Number',
      refs,
    );
    this.refState = CarrierIdentityState.createRefListByGroup('State ID', refs);
    this.refCanadian = CarrierIdentityState.createRefListByGroup(
      'Canadian Province ID',
      refs,
    );
    this.carrierName = carrierName;
    this.identities = new CarrierIdentities(identities, this);
  }

  public static createRefListByGroup(
    group: string,
    refs: RefIdentityDC[],
  ): RefIdentity[] {
    return refs
      .filter((x) => x.group == group)
      .map((x) => {
        return {
          group: x.group,
          type: x.type,
        } as RefIdentity;
      });
  }
}

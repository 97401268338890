import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierFile } from '../carrier/carrier-file/CarrierFile';
import { NewCarrier } from '../carrier/new-carrier-file/NewCarrier';
import { Configuration } from '../shared/configuration/Configuration';
import { AppState } from '../appstate.model';

@Injectable()
export class CarrierService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  createNewManual(newCarrier: NewCarrier): Observable<any> {
    // MC is not a required field, but if you have it, make sure it is prepended with MC
    const mc = newCarrier.mcNumber;
    newCarrier.mcNumber =
      (mc && mc.toUpperCase().startsWith('MC')) || !mc ? mc : 'MC' + mc;
    return this.http.post<any>(
      `${this.config.carrierServiceUrl}newManual`,
      newCarrier,
    );
  }

  getCarrierFileDetails(carrierCode: string): Observable<CarrierFile> {
    return this.http.get<CarrierFile>(
      `${this.config.carrierServiceUrl}${carrierCode}`,
    );
  }
}

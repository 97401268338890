import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../Enums/Permissions';

@Injectable()
export class AdminManagerGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    var result = this.authService.can(Permissions.ViewAdmin);

    if (result) {
      return true;
    }

    this.authService.redirectNotAuthorized();
    return false;
  }
}

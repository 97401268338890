import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CarrierNoCapacity, NoCapacity } from '../models/CarrierNoCapacity';
import { Configuration } from '../../../shared/configuration/Configuration';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';

@Injectable()
export class NoCapacityService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getNoCapacity(carrierCode: string): Observable<CarrierNoCapacity> {
    return this.http
      .get<CarrierNoCapacity>(
        `${this.config.carrierServiceUrl}${carrierCode}/noCapacity`,
      )
      .pipe(
        map((response: CarrierNoCapacity) => {
          response.noCapacity.forEach((x) => {
            return this.MapNoCapacity(x);
          });
          return response;
        }),
      );
  }

  insertNoCapacity(
    carrierCode,
    noCapacity: NoCapacity,
  ): Observable<CarrierNoCapacity> {
    return this.http
      .post<CarrierNoCapacity>(
        `${this.config.carrierServiceUrl}${carrierCode}/noCapacity`,
        noCapacity,
      )
      .pipe(
        map((response: CarrierNoCapacity) => {
          response.noCapacity.forEach((x) => {
            return this.MapNoCapacity(x);
          });
          return response;
        }),
      );
  }

  updateNoCapacity(
    carrierCode: string,
    noCapacity: NoCapacity,
  ): Observable<CarrierNoCapacity> {
    return this.http
      .put<CarrierNoCapacity>(
        `${this.config.carrierServiceUrl}${carrierCode}/noCapacity`,
        noCapacity,
      )
      .pipe(
        map((response: CarrierNoCapacity) => {
          response.noCapacity.forEach((x) => {
            return this.MapNoCapacity(x);
          });
          return response;
        }),
      );
  }

  deleteNoCapacity(
    carrierCode: string,
    carrierNoCapacityID: number,
  ): Observable<CarrierNoCapacity> {
    return this.http
      .delete<CarrierNoCapacity>(
        `${this.config.carrierServiceUrl}${carrierCode}/noCapacity/${carrierNoCapacityID}`,
      )
      .pipe(
        map((response: CarrierNoCapacity) => {
          response.noCapacity.forEach((x) => {
            return this.MapNoCapacity(x);
          });
          return response;
        }),
      );
  }

  private MapNoCapacity(noCapacity: NoCapacity): NoCapacity {
    noCapacity.emptyDateTime = new Date(noCapacity.emptyDateTime);

    if (noCapacity.originAddress) {
      noCapacity.originAddress.cityState = `${noCapacity.originAddress.city}, ${noCapacity.originAddress.stateProvince}`;
    }

    if (noCapacity.destinationAddress) {
      noCapacity.destinationAddress.cityState = `${noCapacity.destinationAddress.city}, ${noCapacity.destinationAddress.stateProvince}`;
    }

    return noCapacity;
  }
}

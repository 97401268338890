import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierCargo, Cargo } from './CarrierCargo';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';

@Injectable()
export class CargoService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getCargoes(carrierCode: string): Observable<CarrierCargo> {
    return this.http.get<CarrierCargo>(
      `${this.config.carrierServiceUrl}${carrierCode}/cargo`,
    );
  }

  updateCarrierCargoes(
    carrierCode: string,
    carrierCargoes: Cargo[],
  ): Observable<CarrierCargo> {
    return this.http.put<CarrierCargo>(
      `${this.config.carrierServiceUrl}${carrierCode}/cargo`,
      carrierCargoes,
    );
  }
}

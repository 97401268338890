import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cacheable } from 'ngx-cacheable';
import {
  CarrierPreferredLane,
  CarrierSummaryDC,
  ShipmentSearchCriteriaModel,
  MatchSearchResult,
  LaneMatch,
} from '../models/LaneMatch';
import { Configuration } from '../../../shared/configuration/Configuration';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';

@Injectable()
export class CarrierLaneMatchingService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  get(carrierCode: string): Observable<CarrierPreferredLane[]> {
    return this.http.get<CarrierPreferredLane[]>(
      `${this.config.carrierServiceUrl}${carrierCode}/preferredlane`,
    );
  }

  cacheId(laneId: number): string {
    return `CarrierLaneMatches-${laneId}`;
  }

  refreshMatch(preferredlane: CarrierPreferredLane): Observable<LaneMatch[]> {
    var cacheId = this.cacheId(preferredlane.carrierPreferredLaneID);
    localStorage.removeItem(cacheId);
    return this.match(preferredlane);
  }

  match(preferredlane: CarrierPreferredLane): Observable<LaneMatch[]> {
    const promise = new Observable<LaneMatch[]>((subscriber) => {
      var cacheId = this.cacheId(preferredlane.carrierPreferredLaneID);
      var cachedMatches = localStorage.getItem(cacheId);

      if (cachedMatches) {
        var result = JSON.parse(cachedMatches) as LaneMatch[];
        subscriber.next(result);
        return;
      }

      var matches: LaneMatch[] = [];

      const criteria = {
        originLatitude: preferredlane.origin.latitude,
        originLongitude: preferredlane.origin.longitude,
        originRadius: preferredlane.originDeadheadMiles,
        destinationLatitude: preferredlane.destination.latitude,
        destinationLongitude: preferredlane.destination.longitude,
        destinationRadius: preferredlane.destinationDeadheadMiles,
        dateAvailable: null,
        truckType: preferredlane.truckType,
      } as ShipmentSearchCriteriaModel;

      this.http
        .post<
          MatchSearchResult[]
        >(`${this.config.shipmentServiceUrl}board/truckloadPostMatch`, criteria)
        .subscribe((response) => {
          response.forEach((truckMatch) => {
            var matchTransform = {
              carrierPreferredLaneID: preferredlane.carrierPreferredLaneID,
              shipmentID: truckMatch.shipmentID,
              primaryReference: truckMatch.primaryReference,
              readyDate: truckMatch.earliestPickup,
              truckOrigin: {
                city: preferredlane.origin.city,
                state: preferredlane.origin.stateProvince,
                latitude: preferredlane.origin.latitude,
                longitude: preferredlane.origin.longitude,
              },
              truckDestination: {
                city: preferredlane.destination.city,
                state: preferredlane.destination.stateProvince,
                latitude: preferredlane.destination.latitude,
                longitude: preferredlane.destination.longitude,
              },
              loadOrigin: {
                city: truckMatch.originCity,
                state: truckMatch.originState,
                deadHead: truckMatch.originDeadHead,
                earliest: truckMatch.earliestPickup,
                latest: truckMatch.latestPickup,
              },
              loadDestination: {
                city: truckMatch.destinationCity,
                state: truckMatch.destinationState,
                deadHead: truckMatch.destinationDeadHead,
                earliest: truckMatch.earliestDelivery,
                latest: truckMatch.latestDelivery,
              },
              loadMiles: truckMatch.distance,
              truckType: preferredlane.truckType,
              weight: truckMatch.weight,
              buyRate: truckMatch.carrierRate,
              sellRate: truckMatch.customerRate,
            } as LaneMatch;

            matches.push(matchTransform);
          });

          // store matches in cache
          var matchesJson = JSON.stringify(matches);
          localStorage.setItem(cacheId, matchesJson);
          subscriber.next(matches);
        });
    });
    return promise;
  }

  @Cacheable()
  getCarrierSummary(carrierCode: string): Observable<CarrierSummaryDC> {
    return this.http.get<CarrierSummaryDC>(
      `${this.config.carrierServiceUrl}${carrierCode}/summary`,
    );
  }
}

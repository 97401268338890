import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NoCapacity } from '../../models/CarrierNoCapacity';
import { AuthService } from '../../../../authentication/_services/auth.service';
import { Permissions } from '../../../../shared/Enums/Permissions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../appstate.model';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CarrierNoCapacityEditComponent } from '../carrier-no-capacity-edit/carrier-no-capacity-edit.component';
import * as TruckPostActions from '../../actions/truck-post.actions';

@Component({
  selector: 'truckload-carrier-no-capacity',
  templateUrl: './carrier-no-capacity.component.html',
  styleUrls: ['./carrier-no-capacity.component.scss'],
})
export class CarrierNoCapacityComponent implements OnInit {
  carrierCode: string;
  inCarrierActivity = false;
  noCapacityData: NoCapacity[];
  itemToRemove: NoCapacity;
  errorMessage: string;
  public loading: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private authService: AuthService,
    private store: Store<AppState>,
  ) {
    store
      .select((x) => x.TruckPost.noCapacity)
      .subscribe((x) => {
        this.itemToRemove = null;
        this.noCapacityData = x.noCapacity;
      });

    store
      .select((x) => x.TruckPost.savingNoCapacity)
      .subscribe((x) => {
        this.loading = x;
      });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.carrierCode = params['id'];
    });
  }

  get canEditPostings(): boolean {
    return this.authService.can(Permissions.EditPostings);
  }

  public editHandler({ dataItem }): void {
    const dialog: DialogRef = this.dialogService.open({
      width: 600,
      content: CarrierNoCapacityEditComponent,
    });
    dialog.content.instance.originalNoCapacity = dataItem;
    dialog.content.instance.isNew = false;
    dialog.content.instance.carrierCode = this.carrierCode;
  }

  public addHandler() {
    const dialog: DialogRef = this.dialogService.open({
      width: 600,
      content: CarrierNoCapacityEditComponent,
    });
    dialog.content.instance.originalNoCapacity = null;
    dialog.content.instance.isNew = true;
    dialog.content.instance.carrierCode = this.carrierCode;
  }

  public removeHandler({ dataItem }): void {
    this.itemToRemove = dataItem;
  }

  private confirmRemove(remove: boolean) {
    if (!remove) {
      this.itemToRemove = null;
      return;
    }
    this.store.dispatch(
      new TruckPostActions.DeleteNoCapacity(
        this.carrierCode,
        this.itemToRemove,
      ),
    );
  }
}

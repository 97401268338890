import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadHistory } from './LoadHistory';
import { Configuration } from '../../../shared/configuration/Configuration';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';

@Injectable()
export class ShipmentService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getLoadHistory(
    dotNumber: string,
    mcNumber: string,
  ): Observable<LoadHistory[]> {
    return this.http
      .get<
        LoadHistory[]
      >(`${this.config.shipmentServiceUrl}shipment/board/carrier/${dotNumber}/${mcNumber}`)
      .pipe(
        map((j: LoadHistory[]) =>
          j.map((x) => {
            x.actualPickup = new Date(x.actualPickup);
            x.actualDelivery = new Date(x.actualDelivery);
            x.loadSource = x.isBlueShipTruckload ? 'BlueShip' : 'Mercury Gate';
            return x;
          }),
        ),
      );
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { TruckPostingDefaultsService } from './truckPostingDefaults.service';
import { TruckPostingDefaults } from './TruckPostingDefaults';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';

@Component({
  selector: 'truckload-carrier-defaults',
  templateUrl: './carrier-defaults.component.html',
  styleUrls: ['./carrier-defaults.component.scss'],
})
export class CarrierDefaultsComponent implements OnInit {
  @Input() carrierCode: string;
  carrierPostDefaults: TruckPostingDefaults;
  originalDefaults: TruckPostingDefaults;
  editMode = false;
  constructor(
    public truckPostDefaultService: TruckPostingDefaultsService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.truckPostDefaultService
      .getCarrierPostDefaults(this.carrierCode)
      .subscribe(
        (data) => {
          this.carrierPostDefaults = data;
        },
        (error) => {
          console.log(error);
        },
      );
  }

  get canEdit(): boolean {
    return this.authService.can(Permissions.EditDefaults);
  }

  edit(): void {
    this.originalDefaults = Object.assign(
      {} as TruckPostingDefaults,
      this.carrierPostDefaults,
    );
    this.toggleEditMode();
  }

  cancel(): void {
    this.carrierPostDefaults = Object.assign(
      {} as TruckPostingDefaults,
      this.originalDefaults,
    );
    this.toggleEditMode();
  }
  save(): void {
    this.truckPostDefaultService
      .save(this.carrierCode, this.carrierPostDefaults)
      .subscribe(
        (data) => {
          this.carrierPostDefaults = data;
        },
        (error) => {
          console.log(error);
        },
      );
    this.toggleEditMode();
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }
}

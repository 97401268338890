// Based on the example at https://www.telerik.com/kendo-angular-ui/components/grid/filtering/reusable-filter/#toc-filter-menu

import {
  Component,
  OnInit,
  AfterViewInit,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  CompositeFilterDescriptor,
  distinct,
  filterBy,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';

@Component({
  selector: 'truckload-multicheck-filter',
  templateUrl: './multicheck-filter.component.html',
  styleUrls: ['./multicheck-filter.component.scss'],
})
export class MultiCheckFilterComponent implements AfterViewInit {
  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public data;
  @Input() public textField;
  @Input() public valueField;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Input() public showFilter: boolean;
  @Output() public valueChange = new EventEmitter<number[]>();

  public filterData: any;
  private value: any[] = [];

  protected textAccessor = (dataItem: any) =>
    this.isPrimitive ? dataItem : dataItem[this.textField];
  protected valueAccessor = (dataItem: any) =>
    this.isPrimitive ? dataItem : dataItem[this.valueField];

  public ngAfterViewInit() {
    if (!this.isPrimitive) {
      const distinctNonPrimitiveList = [];
      this.data.forEach((element) => {
        if (
          !distinctNonPrimitiveList.some((item) => {
            return (
              item[this.valueField] === element[this.field][this.valueField]
            );
          })
        ) {
          distinctNonPrimitiveList.push(element[this.field]);
        }
      });
      this.filterData = distinctNonPrimitiveList;
    } else {
      this.filterData = distinct(this.data, this.field).map(
        (item) => item[this.field],
      );
    }

    this.value = this.currentFilter.filters.map(
      (
        f: FilterDescriptor & { nonPrimitiveValue: string | boolean | number },
      ) =>
        this.isPrimitive
          ? f.value
          : this.filterData.find(
              (fd) => fd[this.valueField] === f.nonPrimitiveValue,
            ),
    );
  }

  public isItemSelected(item) {
    return this.value.some(
      (x) => this.valueAccessor(x) === this.valueAccessor(item),
    );
  }

  public onSelectionChange(item) {
    if (this.isPrimitive) {
      if (this.value.some((x) => x === item)) {
        this.value = this.value.filter((x) => x !== item);
      } else {
        this.value.push(item);
      }
    } else {
      if (this.value.some((x) => this.valueAccessor(x) === item)) {
        this.value = this.value.filter((x) => this.valueAccessor(x) !== item);
      } else {
        this.data.forEach((element) => {
          if (element[this.field][this.valueField] === item) {
            this.value.push(element[this.field]);
          }
        });
      }
    }

    this.filterService.filter({
      filters: this.value.map((value) => ({
        field: this.isPrimitive
          ? this.field
          : `${this.field}.${this.textField}`,
        operator: 'eq',
        value: this.isPrimitive ? value : this.textAccessor(value), // filtering is working on display values for non-primitives...
        nonPrimitiveValue: this.valueAccessor(value),
      })),
      logic: 'or',
    });
  }

  public onInput(e) {
    // TODO: can probably clean this up into 1 distinct?
    var newFilter = distinct(
      [
        ...this.filterData.filter((dataItem) =>
          this.value.some((val) => val === this.valueAccessor(dataItem)),
        ),
        ...filterBy(this.data, {
          operator: 'contains',
          field: this.field,
          value: e.target.value,
        }),
      ],
      this.textField,
    );
    this.filterData = distinct(newFilter, this.field).map(
      (item) => item[this.field],
    );
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { PageService } from '../Services/page.service';
import { MenuItem } from './MenuItems';
import { MenuService } from './MenuService';
import { AuthService } from '../authentication/_services/auth.service';

@Component({
  selector: 'truckload-header',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  pageTitle: string;
  menuItems: Array<MenuItem>;

  constructor(
    private pageService: PageService,
    private menuService: MenuService,
    private authService: AuthService,
  ) {
    this.pageService.pageTitle$.subscribe(
      (pageTitle) => (this.pageTitle = pageTitle),
    );
  }

  ngOnInit() {
    this.menuItems = this.menuService.getMenuItems();
  }

  get userInitials(): string {
    return this.authService.userInitials;
  }

  get userName(): string {
    return this.authService.userName;
  }

  public get isUserEnabled(): boolean {
    return this.authService.isEnabled();
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  public displayProfile(): boolean {
    return this.userName.length > 0;
  }

  public signOut(): void {
    this.authService.logout();
  }

  public canViewHeader(menuItem: MenuItem): boolean {
    if (
      menuItem.canViewPermission != null &&
      menuItem.featureGuardEnabled &&
      this.authService.can(menuItem.canViewPermission)
    ) {
      return true;
    }
    return false;
  }
}

import { Injectable } from '@angular/core';
import { TruckPost } from '../dto/TruckPostDC';
import {
  BatchTruckloadMatchRequest,
  ShipmentSearchCriteriaModel,
  TruckMatch,
} from '../dto/TruckMatchDC';
import { CarrierTruckMatchingService } from './carrier-truck-matching.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CarrierPostMatchService {
  private isRefreshingMatchesSubject = new BehaviorSubject<boolean>(false);
  isRefreshingMatches$ = this.isRefreshingMatchesSubject.asObservable();

  constructor(private matchService: CarrierTruckMatchingService) {}

  async refreshAllMatches(truckPostings: TruckPost[]): Promise<TruckMatch[]> {
    this.isRefreshingMatchesSubject.next(true);

    const request: BatchTruckloadMatchRequest = {
      truckloadPostings: truckPostings.map<ShipmentSearchCriteriaModel>(
        (posting) => ({
          truckPostingID: posting.truckPostingID,
          originLatitude: posting.origin.latitude,
          originLongitude: posting.origin.longitude,
          originRadius: posting.originDeadheadMiles,
          destinationLatitude: posting.destination.latitude,
          destinationLongitude: posting.destination.longitude,
          destinationRadius: posting.destinationDeadheadMiles,
          dateAvailable: posting.emptyDateTime,
          truckType: posting.truckType,
        }),
      ),
    };

    const truckPostMap: Record<TruckPost['truckPostingID'], TruckPost> = {};
    truckPostings.reduce((map, post) => {
      map[post.truckPostingID] = post;
      return map;
    }, truckPostMap);

    return this.matchService
      .getTruckloadPostMatches(request)
      .toPromise()
      .then((response) => {
        const list = response.map<TruckMatch>((truckMatch) => {
          const posting = truckPostMap[truckMatch.truckPostingID];
          return {
            shipmentID: truckMatch.shipmentID,
            primaryReference: truckMatch.primaryReference,
            readyDate: truckMatch.earliestPickup,
            truckOrigin: {
              city: posting.origin.city,
              state: posting.origin.stateProvince,
              latitude: posting.origin.latitude,
              longitude: posting.origin.longitude,
            },
            truckDestination: {
              city: posting.destination.city,
              state: posting.destination.stateProvince,
              latitude: posting.destination.latitude,
              longitude: posting.destination.longitude,
            },
            loadOrigin: {
              city: truckMatch.originCity,
              state: truckMatch.originState,
              deadHead: truckMatch.originDeadHead,
              earliest: truckMatch.earliestPickup,
              latest: truckMatch.latestPickup,
            },
            loadDestination: {
              city: truckMatch.destinationCity,
              state: truckMatch.destinationState,
              deadHead: truckMatch.destinationDeadHead,
              earliest: truckMatch.earliestDelivery,
              latest: truckMatch.latestDelivery,
            },
            loadMiles: truckMatch.distance,
            truckType: posting.truckType,
            weight: truckMatch.weight,
            buyRate: truckMatch.carrierRate,
            sellRate: truckMatch.customerRate,
            carrierRateCurrencyCode: truckMatch.carrierRateCurrencyCode,
            customerRateCurrencyCode: truckMatch.customerRateCurrencyCode,
          } as TruckMatch;
        });
        setTimeout(() => {
          this.isRefreshingMatchesSubject.next(false);
        }, 2000);
        return list;
      })
      .catch((error) => {
        setTimeout(() => {
          this.isRefreshingMatchesSubject.next(false);
        }, 2000);
        throw error;
      });
  }
}

import { Action } from '@ngrx/store';
import { TruckPosting } from '../models/TruckPosting';
import { TruckPostState } from '../models/truck-post-state.model';
import { TruckPost } from '../models/TruckPost';
import { NoCapacity, CarrierNoCapacity } from '../models/CarrierNoCapacity';

// load all data to init truck post state
export const LOAD_TRUCK_POST = '[TRUCK POST API] Load Truck Post';
export const LOAD_TRUCK_POST_SUCCESS =
  '[TRUCK POST API] Load Truck Post Success';
export const LOAD_TRUCK_POST_ERROR = '[TRUCK POST API] Load Truck Post Error';

export class LoadTruckPost implements Action {
  readonly type = LOAD_TRUCK_POST;
  constructor(public carrierCode: string) {}
}
export class LoadTruckPostSuccess implements Action {
  readonly type = LOAD_TRUCK_POST_SUCCESS;
  constructor(public initialState: TruckPostState) {}
}
export class LoadTruckPostError implements Action {
  readonly type = LOAD_TRUCK_POST_ERROR;
  constructor(public message: string) {}
}

// edit truck posts
export const CREATE_TRUCK_POST = '[TRUCK POST API] Create Truck Post';
export const EDIT_TRUCK_POST = '[TRUCK POST API] Edit Truck Post';
export const DELETE_TRUCK_POST = '[TRUCK POST API] Delete Truck Post';
export const UPDATE_TRUCKPOSTING_SUCCESS =
  '[TRUCK POST API] Truck Postings Updated';
export const UPDATE_TRUCKPOSTING_ERROR =
  '[TRUCK POST API] Update Truck Post Error';
export const DELETE_TRUCKPOSTING_SUCCESS =
  '[TRUCK POST API] Truck Posting Deleted';

export class CreateTruckPost implements Action {
  readonly type = CREATE_TRUCK_POST;
  constructor(
    public carrierCode,
    public truckPost: TruckPost,
  ) {}
}
export class EditTruckPost implements Action {
  readonly type = EDIT_TRUCK_POST;
  constructor(
    public carrierCode,
    public truckPost: TruckPost,
  ) {}
}
export class DeleteTruckPost implements Action {
  readonly type = DELETE_TRUCK_POST;
  constructor(
    public carrierCode,
    public truckPost: TruckPost,
  ) {}
}
export class UpdateTruckPostSuccess implements Action {
  readonly type = UPDATE_TRUCKPOSTING_SUCCESS;
  constructor(public results: TruckPosting) {}
}
export class UpdateTruckPostError implements Action {
  readonly type = UPDATE_TRUCKPOSTING_ERROR;
  constructor(public errorMessage: string) {}
}

export class DeleteTruckPostSuccess implements Action {
  readonly type = DELETE_TRUCKPOSTING_SUCCESS;
  constructor(public truckPostingId: TruckPost['truckPostingID']) {}
}

// edit no capacity posts
export const CREATE_NO_CAPACITY = '[TRUCK POST API] Create No Capacity';
export const EDIT_NO_CAPACITY = '[TRUCK POST API] Edit No Capacity';
export const DELETE_NO_CAPACITY = '[TRUCK POST API] Delete No Capacity';
export const UPDATE_NO_CAPACITY_SUCCESS =
  '[TRUCK POST API] No Capacity Updated';
export const UPDATE_NO_CAPACITY_ERROR =
  '[TRUCK POST API] Update No Capacity Error';

export class CreateNoCapacity implements Action {
  readonly type = CREATE_NO_CAPACITY;
  constructor(
    public carrierCode,
    public noCapacity: NoCapacity,
  ) {}
}
export class EditNoCapacity implements Action {
  readonly type = EDIT_NO_CAPACITY;
  constructor(
    public carrierCode,
    public noCapacity: NoCapacity,
  ) {}
}
export class DeleteNoCapacity implements Action {
  readonly type = DELETE_NO_CAPACITY;
  constructor(
    public carrierCode,
    public noCapacity: NoCapacity,
  ) {}
}
export class UpdateNoCapacitySuccess implements Action {
  readonly type = UPDATE_NO_CAPACITY_SUCCESS;
  constructor(public results: CarrierNoCapacity) {}
}
export class UpdateNoCapacityError implements Action {
  readonly type = UPDATE_NO_CAPACITY_ERROR;
  constructor(public errorMessage: string) {}
}

// no truck activity
export const CREATE_NO_TRUCK = '[TRUCK POST API] Create No Truck';
export const UPDATE_NO_TRUCK_SUCCESS = '[TRUCK POST API] No Truck Updated';
export const UPDATE_NO_TRUCK_ERROR = '[TRUCK POST API] Update No Truck Error';

export class CreateNoTruck implements Action {
  readonly type = CREATE_NO_TRUCK;
  constructor(public carrierCode) {}
}
export class UpdateNoTruckSuccess implements Action {
  readonly type = UPDATE_NO_TRUCK_SUCCESS;
  constructor(public results: TruckPosting) {}
}
export class UpdateNoTruckError implements Action {
  readonly type = UPDATE_NO_TRUCK_ERROR;
  constructor(public errorMessage: string) {}
}

export type Actions =
  | LoadTruckPost
  | LoadTruckPostSuccess
  | LoadTruckPostError
  | CreateTruckPost
  | EditTruckPost
  | DeleteTruckPost
  | UpdateTruckPostSuccess
  | UpdateTruckPostError
  | DeleteTruckPostSuccess
  | CreateNoCapacity
  | EditNoCapacity
  | DeleteNoCapacity
  | UpdateNoCapacitySuccess
  | UpdateNoCapacityError
  | CreateNoTruck
  | UpdateNoTruckSuccess
  | UpdateNoTruckError;

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierPreferredLane } from '../models/CarrierPreferredLane';
import { CarrierDefaults } from '../models/CarrierDefaults';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';
import { PreferredLaneState } from '../models/preferred-lane-state.model';
import { RefService } from '../../shared/references/ref.service';
import { AuthService } from '../../authentication/_services/auth.service';
import { Permissions } from '../../shared/Enums/Permissions';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class PreferredLaneService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private refService: RefService,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getState(carrierCode: string): Observable<PreferredLaneState> {
    return new Observable<PreferredLaneState>((observer) => {
      forkJoin([
        this.refService.getTruckTypes(),
        this.refService.getDirections(),
        this.getCarrierDefaults(carrierCode),
        this.get(carrierCode),
      ]).subscribe(
        (response) => {
          let newState = new PreferredLaneState();
          newState.refTruckTypes = response[0];
          newState.refDirections = response[1];
          newState.carrierDefaults = response[2];
          newState.preferredLanes = response[3].map((x) => {
            x.origin.cityState = `${x.origin.city}, ${x.origin.stateProvince}`;
            x.destination.cityState = `${x.destination.city}, ${x.destination.stateProvince}`;
            return x;
          });
          newState.canEditLanes = this.authService.can(Permissions.EditLanes);
          newState.canDeleteLanes = this.authService.can(
            Permissions.DeleteLanes,
          );
          newState.defaultOrigin =
            newState.carrierDefaults == null
              ? null
              : newState.carrierDefaults.origin;
          newState.defaultDestination =
            newState.carrierDefaults == null
              ? null
              : newState.carrierDefaults.destination;
          observer.next(newState);
          observer.complete();
        },
        (err) => {
          console.log(err);
          observer.error(err);
          observer.complete();
        },
      );
    });
  }

  get(carrierCode: string): Observable<CarrierPreferredLane[]> {
    return this.http.get<CarrierPreferredLane[]>(
      `${this.config.carrierServiceUrl}${carrierCode}/preferredlane`,
    );
  }

  save(
    carrierCode: string,
    carrierPreferredLane: CarrierPreferredLane,
  ): Observable<CarrierPreferredLane[]> {
    return this.http.post<CarrierPreferredLane[]>(
      `${this.config.carrierServiceUrl}${carrierCode}/preferredlane`,
      carrierPreferredLane,
    );
  }

  delete(
    carrierCode: string,
    carrierPreferredLaneID: number,
  ): Observable<CarrierPreferredLane[]> {
    return this.http.delete<CarrierPreferredLane[]>(
      `${this.config.carrierServiceUrl}${carrierCode}/preferredlane/${carrierPreferredLaneID}`,
    );
  }

  getCarrierDefaults(carrierCode: string): Observable<CarrierDefaults> {
    return this.http
      .get<CarrierDefaults>(
        `${this.config.carrierServiceUrl}${carrierCode}/truckPostingDefaults`,
      )
      .pipe(
        map((c) => {
          if (c == null) return;
          if (c.origin) {
            c.origin.cityState = `${c.origin.city}, ${c.origin.stateProvince}`;
          }
          if (c.destination) {
            c.destination.cityState = `${c.destination.city}, ${c.destination.stateProvince}`;
          }
          return c;
        }),
      );
  }
}

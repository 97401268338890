import { Injectable } from '@angular/core';
import { Effect, ofType, Actions, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import * as AppActions from './app.actions';
import { ConfigurationService } from './shared/configuration/configuration.service';
import { mergeMap, map } from 'rxjs/operators';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private configService: ConfigurationService,
  ) {}
  // @Effect()
  // $init = this.actions$.pipe(
  //   ofType(ROOT_EFFECTS_INIT),
  //   mergeMap(() =>
  //     this.configService.loadConfigurationData().pipe(
  //       map((config) => {
  //         return new AppActions.LoadAppConfigSuccess(config);
  //       })
  //     )
  //   )
  // );
}

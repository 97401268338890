import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as PreferredLaneActions from '../actions/preferred-lanes.actions';
import { PreferredLaneService } from '../services/preferred-lane.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class PreferredLanesEffects {
  @Effect()
  loadPreferredLanes = this.actions$.pipe(
    ofType(...[PreferredLaneActions.LOAD_PREFERREDLANES]),
    mergeMap((action: PreferredLaneActions.LoadPreferredLanes) => {
      return this.preferredLaneService.getState(action.carrierCode).pipe(
        map((result) => {
          return new PreferredLaneActions.LoadPreferredLanesSuccess(result);
        }),
        catchError((x) => {
          return of(new PreferredLaneActions.LoadPreferredLanesError(x.error));
        }),
      );
    }),
  );

  @Effect()
  deletePreferredLane = this.actions$.pipe(
    ofType(...[PreferredLaneActions.DELETE_PREFERRED_LANE]),
    mergeMap((action: PreferredLaneActions.DeletePreferredLane) =>
      this.preferredLaneService.delete(action.carrierCode, action.laneId).pipe(
        map((result) => {
          return new PreferredLaneActions.UpdatePreferredLaneSuccess(result);
        }),
        catchError((x) => {
          return of(new PreferredLaneActions.LoadPreferredLanesError(x.error));
        }),
      ),
    ),
  );

  @Effect()
  addPreferredLane = this.actions$.pipe(
    ofType(...[PreferredLaneActions.CREATE_PREFERRED_LANE]),
    mergeMap((action: PreferredLaneActions.CreatePreferredLane) =>
      this.preferredLaneService.save(action.carrierCode, action.lane).pipe(
        map((result) => {
          return new PreferredLaneActions.UpdatePreferredLaneSuccess(result);
        }),
        catchError((x) => {
          return of(new PreferredLaneActions.LoadPreferredLanesError(x.error));
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private preferredLaneService: PreferredLaneService,
  ) {}
}

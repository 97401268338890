import {
  ValidatorFn,
  FormGroup,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';

export const identitiesValidator: ValidatorFn = (
  fg: FormGroup,
): ValidationErrors => {
  const mcNumber = fg.get('mcNumber').value;
  const dotNumber = fg.get('dotNumber').value;
  const stateID = fg.get('stateID').value;
  const stateIDNumberState = fg.get('stateIDNumberState').value;

  var errors: ValidationErrors = Object.assign({}) as ValidationErrors;

  if (!stateID && !stateIDNumberState && !dotNumber) {
    errors['missingIdentity'] =
      'At least one identity required (DOT number or State ID)';
  }

  if (stateID && !stateIDNumberState) {
    errors['stateIDNumberStateRequired'] =
      'State required when using a State ID';
  }

  if (!stateID && stateIDNumberState) {
    errors['stateIDRequired'] = 'State ID required when using a state';
  }

  if (dotNumber && (dotNumber.length < 3 || dotNumber.length > 10)) {
    errors['dotLength'] = 'DOT Number must be between 3 and 10 digits';
  }

  if (mcNumber && (mcNumber.length < 6 || mcNumber.length > 8)) {
    errors['mcLength'] =
      'MC Number must be between 6 and 8 digits (Add leading zeros if necessary)';
  }

  if (mcNumber && mcNumber.match(/[a-z]/i)) {
    errors['mcAlpha'] = 'MC must not contain any alpha characters';
  }

  if (mcNumber && mcNumber.match(/(0[0-9]{6})/)) {
    errors['mcDigits'] = 'MC greater than 6 digits cannot start with zeros';
  }

  return errors;
};

import { Injectable } from '@angular/core';
import { MenuItem } from './MenuItems';
import { Permissions } from '../shared/Enums/Permissions';
import { Configuration } from '../shared/configuration/Configuration';
import { Store } from '@ngrx/store';
import { AppState } from '../appstate.model';

@Injectable()
export class MenuService {
  config: Configuration;
  constructor(private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getMenuItems(): MenuItem[] {
    const result: MenuItem[] = [
      <MenuItem>{
        hasChildren: false,
        isSearchable: false,
        id: 'portfolioMenuItem',
        label: 'Portfolio',
        icon: 'fas fa-road',
        routerLink: '/portfolio',
        featureGuardEnabled: true,
        canViewPermission: Permissions.ViewLaneLoadHistory,
      },
      <MenuItem>{
        hasChildren: false,
        isSearchable: false,
        id: 'myMatchesMenuItem',
        label: 'My Matches',
        icon: 'fas fa-road',
        routerLink: '/mymatches',
        featureGuardEnabled: true,
        canViewPermission: Permissions.ViewLaneLoadHistory,
      },
      <MenuItem>{
        hasChildren: false,
        isSearchable: true,
        id: 'carrierMenuItem',
        label: 'Carrier',
        icon: 'fas fa-search-plus',
        routerLink: '/search',
        featureGuardEnabled: this.config.enableCarrierFile,
        canViewPermission: Permissions.ViewCarrier,
      },
      <MenuItem>{
        hasChildren: false,
        isSearchable: false,
        id: 'laneHistoryMenuItem',
        label: 'Lane Load History',
        icon: 'fas fa-road',
        routerLink: '/lanehistory',
        featureGuardEnabled: true,
        canViewPermission: Permissions.ViewLaneLoadHistory,
      },
      <MenuItem>{
        hasChildren: false,
        isSearchable: false,
        id: 'adminMenuItem',
        label: 'Admin',
        icon: 'fas fa-toolbox',
        routerLink: '/admin',
        featureGuardEnabled: true,
        canViewPermission: Permissions.ViewAdmin,
      },
    ];

    return result;
  }
}

import { CarrierNameDC } from '../dto/CarrierNameDC';

export class CarrierName {
  carrierId: number;
  name: string;
  dbaName: string;
  isManual: boolean;

  public constructor(carrier: CarrierNameDC) {
    this.carrierId = carrier.carrierId;
    this.name = carrier.name;
    this.dbaName = carrier.dbaName;
    this.isManual = carrier.isManual;
  }
}

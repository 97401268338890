import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TruckPosting } from '../models/TruckPosting';
import { TruckPost } from '../models/TruckPost';
import { Configuration } from '../../../shared/configuration/Configuration';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';

@Injectable()
export class CarrierTruckPostingService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  get(carrierCode: string): Observable<TruckPosting> {
    return this.http
      .get<TruckPosting>(
        `${this.config.carrierServiceUrl}${carrierCode}/truckposting`,
      )
      .pipe(
        map((p: TruckPosting) => {
          p.truckPostings = p.truckPostings.map((x) => {
            x.emptyDateTime = new Date(x.emptyDateTime);
            x.dateCreated = new Date(x.dateCreated);
            x.origin.cityState = x.origin.city + ', ' + x.origin.stateProvince;
            x.destination.cityState =
              x.destination.city + ', ' + x.destination.stateProvince;
            return x;
          });
          return p;
        }),
      );
  }

  delete(
    carrierCode: string,
    truckPostingID: number,
  ): Observable<TruckPosting> {
    return this.http.delete<TruckPosting>(
      `${this.config.carrierServiceUrl}${carrierCode}/truckposting/${truckPostingID}`,
    );
  }

  insert(
    carrierCode: string,
    carrierPosting: TruckPost,
  ): Observable<TruckPosting> {
    if (carrierPosting.origin) carrierPosting.origin.type = 'Origin';
    if (carrierPosting.destination)
      carrierPosting.destination.type = 'Destination';
    return this.http
      .post<TruckPosting>(
        `${this.config.carrierServiceUrl}${carrierCode}/truckposting`,
        carrierPosting,
      )
      .pipe(
        map((p: TruckPosting) => {
          p.truckPostings = p.truckPostings.map((x) => {
            x.emptyDateTime = new Date(x.emptyDateTime);
            x.dateCreated = new Date(x.dateCreated);
            x.origin.cityState = x.origin.city + ', ' + x.origin.stateProvince;
            x.destination.cityState =
              x.destination.city + ', ' + x.destination.stateProvince;
            return x;
          });
          return p;
        }),
      );
  }

  update(
    carrierCode: string,
    carrierPosting: TruckPost,
  ): Observable<TruckPosting> {
    return this.http
      .put<TruckPosting>(
        `${this.config.carrierServiceUrl}${carrierCode}/truckposting`,
        carrierPosting,
      )
      .pipe(
        map((p: TruckPosting) => {
          p.truckPostings = p.truckPostings.map((x) => {
            x.emptyDateTime = new Date(x.emptyDateTime);
            x.dateCreated = new Date(x.dateCreated);
            x.origin.cityState = x.origin.city + ', ' + x.origin.stateProvince;
            x.destination.cityState =
              x.destination.city + ', ' + x.destination.stateProvince;
            return x;
          });
          return p;
        }),
      );
  }

  noTruck(carrierCode: string): Observable<TruckPosting> {
    return this.http.post<TruckPosting>(
      `${this.config.carrierServiceUrl}${carrierCode}/truckposting/notruck`,
      null,
    );
  }
}

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { NotificationService } from '@progress/kendo-angular-notification';
import { User, UserPermission, Template } from '../User';
import { UserService } from '../users.service';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { AdminService } from '../../admin.service';
import { Permission } from '../../Admin';
@Component({
  selector: 'admin-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
  @ViewChild('permList', { static: true })
  permList;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  public container: ViewContainerRef;

  @ViewChild('errorContainer', { read: ViewContainerRef, static: true })
  public errorContainer: ViewContainerRef;

  @Input()
  appUserName: string;

  @Input()
  buttonLabel: string;

  @Input()
  isNew: boolean;

  @Input()
  actions: boolean;

  @Output()
  userUpdated = new EventEmitter<User>();

  @Output()
  disableActions = new EventEmitter<boolean>();

  editForm: FormGroup;
  editDialog: DialogRef;
  allPerms: Permission[];
  allTemplates: Template[];
  nonAssignedPermissions: Permission[] = [];
  mask: string = '(999) 000-0000';

  public loading: boolean = false;
  public loadingIcon: string = '';

  ngOnInit(): void {}

  constructor(
    private dialogService: DialogService,
    private userService: UserService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private adminService: AdminService,
  ) {}

  private setLoading(state: boolean) {
    this.loading = state;
    this.loadingIcon = state ? 'loading' : '';
  }

  get canManagePermissions(): boolean {
    return this.authService.can(Permissions.ManagePermissions);
  }

  get canEditUser(): boolean {
    if (this.authService.loggedAppUser.appUserName == this.appUserName) {
      return this.canManagePermissions;
    } else {
      return this.canManageUserPermissions;
    }
  }

  get canManageUserPermissions(): boolean {
    return this.authService.can(Permissions.ManageUserPermissions);
  }

  private showEdit(
    contentTemplate: TemplateRef<any>,
    actionsTemplate: TemplateRef<any>,
  ) {
    this.setLoading(true);
    this.loading = true;
    this.disableActions.emit(true);

    if (this.isNew) {
      forkJoin([
        this.adminService.getAllPermissions(),
        this.userService.getAllTemplates(),
      ]).subscribe((results) => {
        this.initAllPerms(results[0]);
        this.initAllTemplates(results[1]);
        this.initForm(null, contentTemplate, actionsTemplate);
        this.setLoading(false);
        this.disableActions.emit(false);
      });
    } else {
      forkJoin([
        this.adminService.getAllPermissions(),
        this.userService.getAllTemplates(),
        this.userService.getByName(this.appUserName),
      ]).subscribe((results) => {
        this.initAllPerms(results[0]);
        this.initAllTemplates(results[1]);
        this.initForm(results[2], contentTemplate, actionsTemplate);
        this.setLoading(false);
        this.disableActions.emit(false);
      });
    }
  }

  // filter out canManageUserPermissions if user doesn't have it already
  // so they can't assign it to themselves
  private initAllPerms(permissions: Permission[]) {
    if (this.canManageUserPermissions) {
      this.allPerms = permissions;
    } else {
      this.allPerms = permissions.filter(
        (x) => x.name != Permissions.ManageUserPermissions,
      );
    }
  }

  private initAllTemplates(templates: Template[]) {
    this.allTemplates = templates.filter(
      (x) => x.permissions.length !== 0 || x.name === 'No Permissions',
    );
  }

  private initNotAssignedPerms(userPermissions: Permission[]) {
    userPermissions.forEach((perm) => {});

    this.nonAssignedPermissions = this.allPerms
      .map((x) => {
        if (userPermissions.find((p) => p.name === x.name)) {
          return null;
        }
        return x;
      })
      .filter((p) => p !== null);

    this.nonAssignedPermissions = this.nonAssignedPermissions
      ? this.nonAssignedPermissions.filter(
          (x) => x.name !== Permissions.ManageUserPermissions,
        )
      : [];
  }

  private initForm(
    user: User,
    contentTemplate: TemplateRef<any>,
    actionsTemplate: TemplateRef<any>,
  ) {
    var title = 'Edit User';

    if (this.isNew) {
      user = {
        permissions: [],
      } as User;
      title = 'New User';
    } else {
      this.initNotAssignedPerms(user.permissions);
    }

    this.createForm(user, title, contentTemplate, actionsTemplate);
  }

  private createForm(
    user: User,
    title: string,
    contentTemplate: TemplateRef<any>,
    actionsTemplate: TemplateRef<any>,
  ) {
    var disabled = !this.canEditUser;
    this.editForm = new FormGroup({
      appUserName: new FormControl(
        { value: user.appUserName, disabled: disabled },
        [Validators.required],
      ),
      email: new FormControl({ value: user.email, disabled: disabled }, [
        Validators.required,
      ]),
      fax: new FormControl({ value: user.fax, disabled: disabled }),
      firstName: new FormControl(
        { value: user.firstName, disabled: disabled },
        [Validators.required],
      ),
      lastName: new FormControl({ value: user.lastName, disabled: disabled }, [
        Validators.required,
      ]),
      phone: new FormControl({ value: user.phone, disabled: disabled }),
      permissions: new FormControl({
        value: user.permissions,
        disabled: disabled,
      }),
    });
    this.editDialog = this.dialogService.open({
      title: title,
      content: contentTemplate,
      actions: actionsTemplate,
      width: 1200,
      height: '95%',
    });
  }

  public save() {
    this.setLoading(true);
    const user = Object.assign({}, this.editForm.value) as User;

    var obsv: Observable<User>;
    if (this.isNew) {
      obsv = this.userService.create(user);
    } else {
      obsv = this.userService.save(user);
    }

    obsv.subscribe(
      (x) => {
        this.setLoading(false);
        this.editDialog.close();
        this.userUpdated.emit(x);
      },
      (e) => {
        this.setLoading(false);
        this.showErrorNotification(`${e.message}`);
      },
    );
  }

  public cancel() {
    this.editDialog.close();
  }

  public isItemSelected(itemText: string): boolean {
    if (itemText == undefined) return false;

    return this.editForm.value.permissions.some(
      (item) => item.name === itemText,
    );
  }

  // assign all permissions to user from template
  public templateSelected(value: any): void {
    if (!this.canEditUser) return;

    // clear permissions if no permissions template selected
    if (value.name == 'No Permissions') {
      this.showTemplatePermissionAddNotification(
        'Removed',
        'No Permissions',
        this.permList.value.length,
      );
      this.permList.reset();
      this.permList.emitValueChange();
      return;
    }

    // find template
    var perms = this.allTemplates.find((x) => x.name == value.name);

    // convert form to user model
    const user = Object.assign({}, this.editForm.value) as User;

    // add permissions from template if they haven't been added yet
    var permAddCount = 0;
    perms.permissions.forEach((x) => {
      if (user.permissions.find((y) => y.name == x.name) == null) {
        permAddCount++;
        this.editForm.value.permissions.push(x);
        this.nonAssignedPermissions = this.nonAssignedPermissions.filter(
          (p) => p.name !== x.name,
        );
      }
    });
    this.showTemplatePermissionAddNotification(
      'Added',
      perms.name,
      permAddCount,
    );
  }

  public permissionSelected(perm: any): void {
    if (!this.canEditUser) {
      return;
    }

    // convert form to user model
    const user = Object.assign({}, this.editForm.value) as User;

    // add permission to user's permission if it hasn't been added yet
    if (user.permissions.find((y) => y.name === perm.name) == null) {
      this.editForm.value.permissions.push(perm);
      this.nonAssignedPermissions = this.nonAssignedPermissions.filter(
        (p) => p.name !== perm.name,
      );
    }
    this.showSuccessNotification(`Added ${perm.name} to user's permission`);
  }

  public valueChanged(permissions: any): void {
    console.log('assigned permissions valueChanged', permissions);
    this.allPerms.forEach((perm) => {
      const user = Object.assign({}, this.editForm.value) as User;
      if (permissions.find((y) => y.name === perm.name) == null) {
        if (
          this.nonAssignedPermissions.find((y) => y.name === perm.name) == null
        ) {
          this.nonAssignedPermissions.push(perm);
        }
      }
    });
  }

  // Notifications //

  public showTemplatePermissionAddNotification(
    msg: string,
    templateName: string,
    numPermsAdded: number,
  ): void {
    this.notificationService.show({
      content: `${msg} ${numPermsAdded} Permissions using ${templateName} Template`,
      appendTo: this.container,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 500 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'info', icon: true },
    });
  }

  public showErrorNotification(msg: string): void {
    this.notificationService.show({
      content: `${msg}`,
      appendTo: this.errorContainer,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 500 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'info', icon: true },
    });
  }

  public showSuccessNotification(msg: string): void {
    this.notificationService.show({
      content: `${msg}`,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 500 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'info', icon: true },
    });
  }

  public onOpen(event: any): void {
    event.preventDefault();
  }
}

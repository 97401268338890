import { Injectable } from '@angular/core';
import { Configuration } from '../../shared/configuration/Configuration';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstate.model';
import { CarrierTruckPostingSearchParameters } from '../models/CarrierTruckPostingSearchParameters';
import { Observable } from 'rxjs';
import { CarrierTruckpostingSearchResult } from '../models/CarrierTruckpostingSearchResults';
import { parseDate } from '@telerik/kendo-intl';

@Injectable({
  providedIn: 'root',
})
export class CarrierTruckpostingSearchService {
  config: Configuration;
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getByTruckPosting(
    params: CarrierTruckPostingSearchParameters,
  ): Observable<CarrierTruckpostingSearchResult[]> {
    const url = `${this.config.carrierServiceUrl}search/carrier/activepostings`;

    const query = {
      originCity: params.Origin.city,
      originState: params.Origin.stateProvince,
      destinationCity: params.Destination.city,
      destinationState: params.Destination.stateProvince,
      oDH: params.ODH,
      dDH: params.DDH,
      startDate: params.StartDate,
      endDate: params.EndDate,
      truckTypeCode: params.TruckTypeCode,
    };

    return this.httpClient.post<CarrierTruckpostingSearchResult[]>(url, query);
  }
}

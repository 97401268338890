import { Component, OnInit, Input } from '@angular/core';
import {
  CarrierIdentities,
  CarrierIdentity,
} from '../../models/CarrierIdentity';
import { Permissions } from '../../../shared/Enums/Permissions';
import { AuthService } from '../../../authentication/_services/auth.service';
import { CarrierName } from '../../models/CarrierName';
import { AppState } from '../../../../app/appstate.model';
import { Store } from '@ngrx/store';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  UpdateCarrierIdentity,
  UpdateCarrierName,
} from '../../actions/carrier-identity.actions';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'truckload-carrier-ids',
  templateUrl: './carrier-ids.component.html',
  styleUrls: ['./carrier-ids.component.scss'],
})
export class CarrierIdsComponent implements OnInit {
  @Input()
  carrierCode: string;

  editMode: boolean;

  docketWarning: string;
  dotWarning: string;
  generalWarning: string;
  stateWarning: string;

  loading: boolean;
  serverErrorMessage: string;

  formIdentities: FormGroup;
  formCarrierName: FormGroup;

  carrierName: CarrierName;
  refIdentifier: string[];
  refSystem: string[];
  refFinancial: string[];
  refDocket: string[];
  refState: string[];
  refCanadian: string[];
  identities: CarrierIdentities;

  newID: string;
  newStateID: string;
  newCanadianID: string;

  public availableIdentifier: string[];
  public availableSystem: string[];
  public availableFinancial: string[];
  public availableDocket: string[];
  public availableState: string[];
  public availableCanadian: string[];
  public availableAll: string[];

  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.store
      .select((x) => x.CarrierIdentity)
      .subscribe((x) => {
        this.refIdentifier = x.refIdentifier.map((c) => c.type);
        this.refSystem = x.refSystem.map((c) => c.type);
        this.refFinancial = x.refFinancial.map((c) => c.type);
        this.refDocket = x.refDocket.map((c) => c.type);
        this.refState = x.refState.map((c) => c.type);
        this.refCanadian = x.refCanadian.map((c) => c.type);
      });

    this.store
      .select((x) => x.CarrierIdentity.carrierName)
      .subscribe((x) => {
        this.carrierName =
          x instanceof HttpErrorResponse ? this.carrierName : x;
      });

    this.store
      .select((x) => x.CarrierIdentity.loading)
      .subscribe((x) => {
        this.loading = x;

        if (!this.loading) {
          this.serverErrorMessage = null;
        }
      });

    this.store
      .select((x) => x.CarrierIdentity.loadingError)
      .subscribe((x) => {
        this.serverErrorMessage = x;
      });

    this.store
      .select((x) => x.CarrierIdentity.updateLoading)
      .subscribe((x) => {
        this.loading = x;

        if (!this.loading) {
          this.serverErrorMessage = null;
        }
      });

    this.store
      .select((x) => x.CarrierIdentity.updateError)
      .subscribe((x) => {
        this.serverErrorMessage = x;
      });

    this.store
      .select((x) => x.CarrierIdentity.identities)
      .subscribe((x) => {
        if (x == null) {
          return;
        }
        this.identities = x;
        this.availableAll = [...this.identities.availableAll];
        this.availableSystem = [...this.identities.availableSystem];
        this.availableIdentifier = [...this.identities.availableIdentifier];
        this.availableDocket = [...this.identities.availableDocket];
        this.availableFinancial = [...this.identities.availableFinancial];
        this.availableState = [...this.identities.availableState];
        this.availableCanadian = [...this.identities.availableCanadian];
      });
  }

  private createIdentitiesForm() {
    const systemArray = this.createIdentityArray(this.identities.systemGroup);
    const identifierArray = this.createIdentityArray(
      this.identities.identifierGroup,
    );
    const docketArray = this.createIdentityArray(this.identities.docketGroup);
    const financialArray = this.createIdentityArray(
      this.identities.financialGroup,
    );
    const stateValue = this.identities.stateGroup.find((x) => x.value);
    const canadianValue = this.identities.canadianGroup.find((x) => x.value);

    this.formIdentities = new FormGroup({
      system: systemArray,
      identifier: identifierArray,
      docket: docketArray,
      financial: financialArray,
      state: new FormControl(stateValue ? stateValue.value : null),
      canadian: new FormControl(canadianValue ? canadianValue.value : null),
      dbaName: new FormControl(this.carrierName.dbaName),
      name: new FormControl(this.carrierName.name, Validators.required),
    });
  }

  private addIdentifier(type: string) {
    const newControl = new FormGroup({
      type: new FormControl(type),
      value: new FormControl(null),
    });
    this.addIdentifierGroup(type, newControl);
  }

  private addIdentifierGroup(type: string, group: FormGroup) {
    if (type == null) {
      return;
    }

    const index = this.availableAll.findIndex((x) => x === type);
    const sysIndex = this.availableSystem.findIndex((x) => x === type);
    const ideIndex = this.availableIdentifier.findIndex((x) => x === type);
    const docIndex = this.availableDocket.findIndex((x) => x === type);
    const finIndex = this.availableFinancial.findIndex((x) => x === type);

    if (sysIndex !== -1) {
      this.systemArray.push(group);
      this.availableSystem.splice(sysIndex, 1);
    }

    if (this.refIdentifier.findIndex((x) => x === type) !== -1) {
      this.identifierArray.push(group);
      this.availableIdentifier.splice(ideIndex, 1);
    }

    if (this.refDocket.findIndex((x) => x === type) !== -1) {
      this.docketArray.push(group);
      this.availableDocket.splice(docIndex, 1);
    }
    if (this.refFinancial.findIndex((x) => x === type) !== -1) {
      this.financialArray.push(group);
      this.availableFinancial.splice(finIndex, 1);
    }

    this.availableAll.splice(index, 1);

    this.newID = null;
  }

  private createIdentityArray(ids: CarrierIdentity[]): FormArray {
    return new FormArray(
      ids.map((x) => {
        return new FormGroup({
          type: new FormControl(x.type, Validators.required),
          value: new FormControl(x.value, Validators.required),
        });
      }),
    );
  }

  get systemArray(): FormArray {
    return this.formIdentities.get('system') as FormArray;
  }

  get identifierArray(): FormArray {
    return this.formIdentities.get('identifier') as FormArray;
  }

  get docketArray(): FormArray {
    return this.formIdentities.get('docket') as FormArray;
  }

  get financialArray(): FormArray {
    return this.formIdentities.get('financial') as FormArray;
  }

  get hasStateId(): boolean {
    return this.formIdentities.get('state').value != null;
  }

  get hasCanadianId(): boolean {
    return this.formIdentities.get('canadian').value != null;
  }

  get canEdit(): boolean {
    return this.authService.can(Permissions.EditCarrierIDs);
  }

  edit() {
    this.toggleEditMode();
  }

  cancel() {
    this.toggleEditMode();
  }

  save() {
    const formValue = this.formIdentities.value;

    const newIdentities = {
      systemGroup: formValue.system,
      identifierGroup: formValue.identifier,
      docketGroup: formValue.docket,
      financialGroup: formValue.financial,
      stateGroup:
        formValue.state != null
          ? [{ type: formValue.state, value: formValue.state }]
          : [],
      canadianGroup:
        formValue.canadian != null
          ? [{ type: formValue.canadian, value: formValue.canadian }]
          : [],
    } as CarrierIdentities;

    this.store.dispatch(
      new UpdateCarrierIdentity(this.carrierCode, newIdentities),
    );

    const carrierName = {
      carrierId: this.carrierName.carrierId,
      name: formValue.name,
      dbaName: formValue.dbaName,
      isManual: this.carrierName.isManual,
    } as CarrierName;

    this.store.dispatch(new UpdateCarrierName(this.carrierCode, carrierName));

    this.toggleEditMode();
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;

    if (this.editMode) {
      this.createIdentitiesForm();
      // this.createCarrierNameForm();
    } else {
      this.availableAll = [...this.identities.availableAll];
      this.availableSystem = [...this.identities.availableSystem];
      this.availableIdentifier = [...this.identities.availableIdentifier];
      this.availableDocket = [...this.identities.availableDocket];
      this.availableFinancial = [...this.identities.availableFinancial];
      this.availableState = [...this.identities.availableState];
      this.availableCanadian = [...this.identities.availableCanadian];
    }
  }
}

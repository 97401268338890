import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TruckPosting } from '../dto/TruckPostDC';
import {
  MatchSearchResult,
  BatchTruckloadMatchRequest,
} from '../dto/TruckMatchDC';
import { CarrierSummaryDC } from '../../dto/CarrierSummaryDC';
import { Cacheable } from 'ngx-cacheable';
import { Configuration } from '../../../shared/configuration/Configuration';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';

@Injectable()
export class CarrierTruckMatchingService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  get(carrierCode: string): Observable<TruckPosting> {
    return this.http
      .get<TruckPosting>(
        `${this.config.carrierServiceUrl}${carrierCode}/truckposting`,
      )
      .pipe(
        map((p: TruckPosting) => {
          p.truckPostings = p.truckPostings.map((x) => {
            x.emptyDateTime = new Date(x.emptyDateTime);
            x.dateCreated = new Date(x.dateCreated + 'Z');
            x.origin.cityState = x.origin.city + ', ' + x.origin.stateProvince;
            x.destination.cityState =
              x.destination.city + ', ' + x.destination.stateProvince;
            return x;
          });
          return p;
        }),
      );
  }

  getTruckloadPostMatches(
    request: BatchTruckloadMatchRequest,
  ): Observable<MatchSearchResult[]> {
    return this.http.post<MatchSearchResult[]>(
      `${this.config.shipmentServiceUrl}board/truckloadPostMatch`,
      request,
    );
  }

  @Cacheable()
  getCarrierSummary(carrierCode: string): Observable<CarrierSummaryDC> {
    return this.http.get<CarrierSummaryDC>(
      `${this.config.carrierServiceUrl}${carrierCode}/summary`,
    );
  }
}

import { Injectable } from '@angular/core';
import { AppUser } from '../_models/AppUser';

@Injectable()
export class PermissionService {
  constructor() {}

  can(name: string, appUser: AppUser): boolean {
    if (!name.startsWith('can')) {
      name = `can${name}`;
    }
    if (appUser) {
      const permission = appUser.permissions.find(
        (a) => a.name.trim().toLowerCase() === name.trim().toLowerCase(),
      );
      return permission && permission !== undefined ? true : false;
    }
    return false;
  }

  some(names: string[], appUser: AppUser): boolean {
    let isInPermission = false;
    names.forEach((val) => {
      if (this.can(val, appUser)) {
        isInPermission = true;
      }
    });
    return isInPermission;
  }

  all(names: string[], appUser: AppUser): boolean {
    let numMatched = 0;
    names.forEach((val) => {
      if (this.can(val, appUser)) {
        numMatched++;
      }
    });
    return names.length && numMatched === names.length;
  }
}

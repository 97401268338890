import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierIdentityState } from '../models/carrier-identity-state.model';
import { Configuration } from '../../../app/shared/configuration/Configuration';
import { AppState } from '../../../app/appstate.model';
import { CarrierName } from '../models/CarrierName';
import { CarrierIdentities, CarrierIdentity } from '../models/CarrierIdentity';
import { CarrierNameDC } from '../dto/CarrierNameDC';
import { CarrierIdentityDC } from '../dto/CarrierIdentityDC';
import { RefIdentityDC } from '../dto/RefIdentityDC';

@Injectable()
export class CarrierIdentityService {
  config: Configuration;
  cachedState: CarrierIdentityState;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getState(carrierCode: string): Observable<CarrierIdentityState> {
    return new Observable<CarrierIdentityState>((observer) => {
      let $getIdentity = this.http.get<CarrierIdentityDC[]>(
        `${this.config.carrierServiceUrl}${carrierCode}/identity`,
      );
      let $getRefIdentity = this.http.get<RefIdentityDC[]>(
        `${this.config.carrierServiceUrl}reference/identity`,
      );
      let $getCarrierName = this.http.get<CarrierName>(
        `${this.config.carrierServiceUrl}${carrierCode}/carrierName`,
      );
      forkJoin([$getIdentity, $getRefIdentity, $getCarrierName]).subscribe(
        (x) => {
          this.cachedState = new CarrierIdentityState(x[0], x[1], x[2]);
          observer.next(this.cachedState);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        },
      );
    });
  }

  updateCarrierIDs(
    carrierCode: string,
    ids: CarrierIdentities,
  ): Observable<CarrierIdentities> {
    return new Observable<CarrierIdentities>((observer) => {
      let allIds = ids.systemGroup.concat(
        ids.identifierGroup,
        ids.financialGroup,
        ids.docketGroup,
        ids.stateGroup,
        ids.canadianGroup,
      );

      let dc = allIds.map((x) => {
        return {
          type: x.type,
          value: x.value,
          isState: this.isStateId(x.value),
        } as CarrierIdentityDC;
      });

      this.http
        .put<
          CarrierIdentityDC[]
        >(`${this.config.carrierServiceUrl}${carrierCode}/identity`, dc)
        .subscribe(
          (x) => {
            observer.next(new CarrierIdentities(x, this.cachedState));
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          },
        );
    });
  }

  updateCarrierName(
    carrierCode: string,
    carrierName: CarrierName,
  ): Observable<CarrierName> {
    return new Observable<CarrierName>((observer) => {
      var dc = new CarrierNameDC(carrierName);
      this.http
        .put<CarrierName>(
          `${this.config.carrierServiceUrl}${carrierCode}/carrierName`,
          dc,
        )
        .subscribe(
          (x) => {
            observer.next(new CarrierName(x));
            observer.complete();
          },
          (err) => {
            observer.next(err);
            observer.complete();
          },
        );
    });
  }

  private isStateId(stateId: string): boolean {
    return this.cachedState.refState.findIndex((x) => x.type == stateId) != -1;
  }
}

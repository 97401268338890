import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierLaneSearchParameters } from '../models/CarrierLaneSearchParameters';
import { CarrierLaneSearchResult } from '../models/CarrierLaneSearchResults';
import { PreferredLaneSearchRequestDTO } from '../dto/PreferredLaneSearchRequestDTO';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';

@Injectable()
export class CarrierLaneSearchService {
  config: Configuration;
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getSearchPreferredLane(
    params: CarrierLaneSearchParameters,
  ): Observable<CarrierLaneSearchResult[]> {
    const url = `${this.config.carrierServiceUrl}Search/lane`;
    var request = new PreferredLaneSearchRequestDTO(params);
    return this.httpClient.post<CarrierLaneSearchResult[]>(url, request).pipe(
      map((x) => {
        var todaysDate = new Date();
        todaysDate.setHours(0, 0, 0, 0);
        x.map((y) => {
          y.lastContacted = new Date(y.lastContacted);
          y.contactedToday =
            y.lastContacted != null && y.lastContacted >= todaysDate;
          y.originLocation = `${y.originCity}, ${y.originStateProvince}`;
          y.destinationLocation = `${y.destinationCity}, ${y.destinationStateProvince}`;
        });
        return x;
      }),
    );
  }
}

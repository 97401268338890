import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import {
  CarrierTruckTypeService,
  CarrierTruckTypeInventory,
} from './carrier-truck-type.service';
import { RefService } from '../references/ref.service';
import { forkJoin } from 'rxjs';
import { RefTruckType } from '../references/RefTruckType';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'truckload-carrier-truck-type-select',
  templateUrl: './carrier-truck-type-select.component.html',
  styleUrls: ['./carrier-truck-type-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CarrierTruckTypeSelectComponent),
      multi: true,
    },
  ],
})
export class CarrierTruckTypeSelectComponent
  implements OnInit, ControlValueAccessor
{
  @Input()
  set truckTypeCode(val: string) {
    this._truckTypeCode = val;
    this.truckTypeCodeChange.emit(this._truckTypeCode);
    if (this.onChange) {
      this.onChange(this._truckTypeCode);
    }
  }
  get truckTypeCode(): string {
    return this._truckTypeCode;
  }
  @Output() truckTypeCodeChange = new EventEmitter();
  @Input() carrierCode: string;
  @Input() isRequired: boolean;
  onChange: any;
  public truckTypeCodes: string[];
  private _truckTypeCode: string;

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this._truckTypeCode = obj;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  constructor(
    public carrierTruckTypesService: CarrierTruckTypeService,
    public refService: RefService,
  ) {}

  ngOnInit() {
    forkJoin([
      this.carrierTruckTypesService.getCarrierTruckTypes(this.carrierCode),
      this.refService.getTruckTypes(),
    ]).subscribe((response) => {
      let truckTypeList = new Array<string>();
      const carrierInventory: CarrierTruckTypeInventory = response[0];
      const refTruckTypes: RefTruckType[] = response[1];
      const invCount = carrierInventory.inventory.length;
      const has0Inventory =
        invCount && carrierInventory.inventory.every((x) => x.inventory == 0);
      if (!invCount || has0Inventory) {
        refTruckTypes.forEach((x) => {
          truckTypeList = refTruckTypes.map((x) => x.code);
        });
      } else {
        carrierInventory.inventory
          .filter((x) => x.inventory > 0)
          .forEach((t) => truckTypeList.push(t.code));
      }
      if (!this.isRequired) {
        truckTypeList.splice(0, 0, '');
      }
      this.truckTypeCodes = truckTypeList;
    });
  }
}

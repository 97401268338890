import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { CarrierNote } from './CarrierNote';
import { NoteService } from './note.service';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CarrierErrors } from '../CarrierErrors';

@Component({
  selector: 'truckload-carrier-notes',
  templateUrl: './carrier-notes.component.html',
  styleUrls: ['./carrier-notes.component.scss'],
})
export class CarrierNotesComponent implements OnInit {
  @Input()
  carrierCode: string;
  @ViewChild('carrierNotesGrid', { static: true })
  carrierNotesGrid: GridComponent;
  @Output()
  openErrorWindow: EventEmitter<CarrierErrors> = new EventEmitter();

  carrierNotesGridData: CarrierNote[];
  authUserName: any;
  loading = false;

  canAddNotes: boolean;
  canManageNotes: boolean;
  canManageAllNotes: boolean;

  itemToRemove: any;

  constructor(
    private noteService: NoteService,
    private authService: AuthService,
  ) {
    this.createNewNote = this.createNewNote.bind(this);
    this.removeConfirmation = this.removeConfirmation.bind(this);
  }

  ngOnInit() {
    this.authUserName = this.authService.authUser.profile.name;
    this.canManageNotes = this.authService.can(Permissions.ManageNotes);
    this.canManageAllNotes = this.authService.can(Permissions.ManageAllNotes);
    this.canAddNotes = this.canManageNotes || this.canManageAllNotes;
    this.get();
  }

  get(): void {
    this.loading = true;
    this.noteService.get(this.carrierCode).subscribe(
      (response) => {
        this.carrierNotesGridData = response;
        this.loading = false;
      },
      (err) => {
        const error = {} as CarrierErrors;
        error.redirectToCarrierSearch = false;
        error.errors = [`${err.status}: ${err.statusText}`];
        this.openErrorWindow.emit(error);
        this.loading = false;
      },
    );
  }

  createNewNote() {
    return {} as CarrierNote;
  }

  public saveHandler({ sender, rowIndex, dataItem, isNew }): void {
    dataItem.bgCode = this.carrierCode;
    dataItem.userCreated = this.authUserName;
    this.noteService.save(this.carrierCode, dataItem).subscribe(
      (data) => {
        this.carrierNotesGridData = data;
      },
      (err) => {
        const error = {} as CarrierErrors;
        error.redirectToCarrierSearch = false;
        error.errors = [`${err.status}: ${err.statusText}`];
        this.openErrorWindow.emit(error);
        this.get();
      },
    );
    sender.closeRow(rowIndex);
  }

  public confirmRemove(shouldRemove: boolean): void {
    if (!shouldRemove) {
      this.itemToRemove = null;
      return;
    }
    if (
      !(
        this.itemToRemove.userCreated === this.authUserName ||
        this.canManageAllNotes
      )
    ) {
      this.itemToRemove = null;
      return;
    }

    this.noteService
      .delete(this.carrierCode, this.itemToRemove.carrierNoteID)
      .subscribe(
        (data) => {
          this.carrierNotesGridData = data;
        },
        (err) => {
          const error = {} as CarrierErrors;
          error.redirectToCarrierSearch = false;
          error.errors = [`${err.status}: ${err.statusText}`];
          this.openErrorWindow.emit(error);
          this.get();
        },
        () => {
          this.itemToRemove = null;
        },
      );
  }

  public removeConfirmation(dataItem): any {
    this.itemToRemove = dataItem;
    return false;
  }

  canDeleteNotes(userCreated: string): boolean {
    if (
      (userCreated === this.authUserName && this.canManageNotes) ||
      this.canManageAllNotes
    ) {
      return true;
    }
    return false;
  }
}

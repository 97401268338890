import { CarrierRoutingModule } from './carrier-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateInputModule } from '@progress/kendo-angular-dateinputs';
import { NotificationModule } from '@progress/kendo-angular-notification';
import {
  BodyModule,
  FilterMenuModule,
  GridModule,
  RowFilterModule,
  SharedModule,
} from '@progress/kendo-angular-grid';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import {
  DropDownsModule,
  DropDownListModule,
  MultiSelectModule,
  ComboBoxModule,
} from '@progress/kendo-angular-dropdowns';
import { WindowModule } from '@progress/kendo-angular-dialog';
import {
  DatePickerModule,
  TimePickerModule,
} from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IntlModule } from '@progress/kendo-angular-intl';
import { UploadModule } from '@progress/kendo-angular-upload';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { LabelModule } from '@progress/kendo-angular-label';

import { CommonService } from '../Services/common.service';
import { CarrierService } from '../Services/carrier.service';
import { ShipmentService } from './carrier-file/load-history/shipment.service';
import { PreferredLaneService } from './services/preferred-lane.service';

import { CarrierActiveStatusComponent } from './carrier-file/carrier-active-status/carrier-active-status.component';
import { PreferredLanesComponent } from './carrier-file/preferred-lane/preferred-lanes.component';
import { CarrierFileComponent } from './carrier-file/carrier-file.component';
import { CarrierNotesComponent } from './carrier-file/carrier-notes/carrier-notes.component';
import { PostMatchDetailsComponent } from './post-match-details/post-match-details.component';
import { NewCarrierFileComponent } from './new-carrier-file/new-carrier-file.component';
import { CarrierTruckPostingComponent } from './post-match-details/components/carrier-truck-posting/carrier-truck-posting.component';
import { CarrierTruckMatchingComponent } from './post-match-details/components/carrier-truck-matching/carrier-truck-matching.component';
import { CarrierNoCapacityComponent } from './post-match-details/components/carrier-no-capacity/carrier-no-capacity.component';
import { CarrierFileServicesComponent } from './carrier-file/carrier-file-services/carrier-file-services.component';
import { CarrierInspectionsComponent } from './carrier-file/carrier-inspections/carrier-inspections.component';
import { CarrierFileManagerGuard } from '../shared/guards/carrier/carrier-file-manager.guard';
import { LoadHistoryComponent } from './carrier-file/load-history/load-history.component';
import { CarrierFileContactsComponent } from './carrier-file/carrier-file-contacts/carrier-file-contacts.component';
import { CarrierDocumentsComponent } from './carrier-file/carrier-documents/carrier-documents.component';
import { CarrierAddressesComponent } from './carrier-file/carrier-addresses/carrier-addresses.component';
import { CarrierInsuranceComponent } from './carrier-file/carrier-insurance/carrier-insurance.component';
import { CarrierFileCertificationsComponent } from './carrier-file/carrier-file-certifications/carrier-file-certifications.component';
import { CargoCarriedComponent } from './carrier-file/cargo-carried/cargo-carried.component';
import { CarrierInventoryComponent } from './carrier-file/carrier-inventory/carrier-inventory.component';
import { NewCarrierManagerGuard } from '../shared/guards/carrier/new-carrier-manager.guard';
import { DocumentService } from './carrier-file/carrier-documents/document.service';
import { AddressService } from './carrier-file/carrier-addresses/address.service';
import { CargoService } from './carrier-file/cargo-carried/cargo.service';
import { CertificationService } from './carrier-file/carrier-file-certifications/certification.service';
import { ContactService } from './carrier-file/carrier-file-contacts/contact.service';
import { ServiceService } from './carrier-file/carrier-file-services/service.service';
import { InspectionService } from './carrier-file/carrier-inspections/inspection.service';
import { InsuranceService } from './carrier-file/carrier-insurance/insurance.service';
import { NoteService } from './carrier-file/carrier-notes/note.service';
import { InventoryService } from './carrier-file/carrier-inventory/inventory.service';
import { CarrierAssociationService } from './carrier-file/carrier-association/association.service';
import { CarrierAssociationComponent } from './carrier-file/carrier-association/carrier-association.component';
import { CarrierDetailsComponent } from './carrier-file/carrier-details/carrier-details.component';
import { DetailsService } from './services/details.service';
import { NoCapacityService } from './post-match-details/services/carrier-no-capacity.service';
import { CarrierTruckPostingService } from './post-match-details/services/carrier-truck-posting.service';
import { CarrierTruckMatchingService } from './post-match-details/services/carrier-truck-matching.service';
import { CarrierPostMatchService } from './post-match-details/services/carrier-truck-postmatch.service';
import { CarrierIdsComponent } from './carrier-file/carrier-ids/carrier-ids.component';
import { CarrierIdentityService } from './services/identity-service';
import { CarrierProfileOfferComponent } from './carrier-file/carrier-profile-offer/carrier-profile-offer.component';
import { CarrierProfileOfferService } from './carrier-file/carrier-profile-offer/carrier-profile-offer.service';
import { PostMatchDetailsService } from './post-match-details/services/post-match-details.service';
import { CarrierDefaultsComponent } from './carrier-file/carrier-defaults/carrier-defaults.component';
import { TruckPostingDefaultsService } from './carrier-file/carrier-defaults/truckPostingDefaults.service';
import { CarrierTruckPostingDetailsComponent } from './post-match-details/components/carrier-truck-posting-details/carrier-truck-posting-details.component';
import { CarrierTruckPostingEditComponent } from './post-match-details/components/carrier-truck-posting-edit/carrier-truck-posting-edit.component';
import { CarrierNoCapacityEditComponent } from './post-match-details/components/carrier-no-capacity-edit/carrier-no-capacity-edit.component';
import { CarrierLaneMatchingComponent } from './post-match-details/components/carrier-lane-matching/carrier-lane-matching.component';
import { CarrierLaneMatchingService } from './post-match-details/services/carrier-lane-matching.service';
import { TrackingPreferenceComponent } from './carrier-file/tracking-preference/tracking-preference.component';
import { TrackingPreferenceService } from './services/tracking-preference.service';
import { CarrierEditService } from './services/carrier-edit-service';
import { TrackingPreferenceEditComponent } from './carrier-file/tracking-preference-edit/tracking-preference-edit.component';
import { TruckPostMatchEditService } from './post-match-details/services/truck-post-match-edit.service';

import { PreferredLaneEditComponent } from './carrier-file/preferred-lane-edit/preferred-lane-edit.component';
import { CarrierCrmLeadStatusComponent } from './carrier-file/carrier-crm-lead-status/carrier-crm-lead-status.component';
import { TruckPostEffects } from './post-match-details/effects/truck-post.effects';
import { truckPostReducer } from './post-match-details/reducers/truck-post.reducer';
import { preferredLanesReducer } from './reducers/preferred-lanes.reducer';
import { PreferredLanesEffects } from './effects/preferred-lanes.effects';
import { carrierDetailReducer } from './reducers/details.reducer';
import { CarrierDetailsEffects } from './effects/carrier-details.effects';
import { CarrierLoadHistoryComponent } from './carrier-file/carrier-load-history/carrier-load-history.component';
import { carrierIdentityReducer } from './reducers/identity.reducer';
import { CarrierIdentityEffects } from './effects/carrier-identity.effects';
import { SyncCarrierFileComponent } from './sync-carrier-file/sync-carrier-file.component';
import { SharedModule as BGSharedModule } from '../shared/shared.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CarrierRoutingModule,
    BodyModule,
    FilterMenuModule,
    GridModule,
    SharedModule,
    RowFilterModule,
    ButtonModule,
    UploadModule,
    DropDownListModule,
    WindowModule,
    MultiSelectModule,
    DatePickerModule,
    ButtonsModule,
    DateInputModule,
    DialogModule,
    LabelModule,
    TimePickerModule,
    InputsModule,
    LayoutModule,
    IntlModule,
    SharedModule,
    ComboBoxModule,
    DropDownsModule,
    NotificationModule,
    StoreModule.forFeature('TruckPost', truckPostReducer),
    StoreModule.forFeature('PreferredLanes', preferredLanesReducer),
    StoreModule.forFeature('CarrierDetails', carrierDetailReducer),
    StoreModule.forFeature('CarrierIdentity', carrierIdentityReducer),
    EffectsModule.forFeature([
      TruckPostEffects,
      PreferredLanesEffects,
      CarrierDetailsEffects,
      CarrierIdentityEffects,
    ]),
    BGSharedModule,
  ],
  declarations: [
    CarrierFileComponent,
    CarrierNotesComponent,
    PostMatchDetailsComponent,
    CarrierTruckPostingComponent,
    CarrierTruckMatchingComponent,
    CarrierNoCapacityComponent,
    NewCarrierFileComponent,
    CarrierFileContactsComponent,
    CarrierActiveStatusComponent,
    PreferredLanesComponent,
    CargoCarriedComponent,
    CarrierDocumentsComponent,
    LoadHistoryComponent,
    CarrierInspectionsComponent,
    CarrierAddressesComponent,
    CarrierInsuranceComponent,
    CarrierFileServicesComponent,
    CarrierInspectionsComponent,
    CarrierAddressesComponent,
    CarrierFileCertificationsComponent,
    CarrierInventoryComponent,
    CarrierAssociationComponent,
    CarrierDetailsComponent,
    CarrierIdsComponent,
    CarrierProfileOfferComponent,
    CarrierDefaultsComponent,
    CarrierTruckPostingDetailsComponent,
    CarrierTruckPostingEditComponent,
    CarrierNoCapacityEditComponent,
    CarrierLaneMatchingComponent,
    TrackingPreferenceComponent,
    TrackingPreferenceEditComponent,
    PreferredLaneEditComponent,
    CarrierLoadHistoryComponent,
    CarrierCrmLeadStatusComponent,
    SyncCarrierFileComponent,
  ],
  providers: [
    CommonService,
    CarrierService,
    DocumentService,
    AddressService,
    CargoService,
    CertificationService,
    ContactService,
    ServiceService,
    InspectionService,
    InsuranceService,
    CarrierAssociationService,
    NoteService,
    ShipmentService,
    PreferredLaneService,
    InventoryService,
    DetailsService,
    CarrierTruckPostingService,
    CarrierTruckMatchingService,
    CarrierPostMatchService,
    NoCapacityService,
    CarrierIdentityService,
    CarrierProfileOfferService,
    PostMatchDetailsService,
    TruckPostingDefaultsService,
    CarrierLaneMatchingService,
    TrackingPreferenceService,
    CarrierEditService,
    TruckPostMatchEditService,
    // guards
    CarrierFileManagerGuard,
    NewCarrierManagerGuard,
  ],
  entryComponents: [
    TrackingPreferenceEditComponent,
    CarrierTruckPostingEditComponent,
    CarrierNoCapacityEditComponent,
    PreferredLaneEditComponent,
  ],
})
export class CarrierModule {}

import { Component, OnInit, Input } from '@angular/core';
import { LoadHistoryDC } from './LoadHistoryDC';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../../app/appstate.model';
import { Store } from '@ngrx/store';
import { ShipmentService } from '../load-history/shipment.service';
import { CarrierLoadHistoryService } from './carrier-load-history.service';

@Component({
  selector: 'truckload-carrier-load-history',
  templateUrl: './carrier-load-history.component.html',
  styleUrls: ['./carrier-load-history.component.scss'],
})
export class CarrierLoadHistoryComponent implements OnInit {
  public loading: boolean;
  @Input()
  carrierCode: string;

  loadHistoryGridData: LoadHistoryDC[];
  config: Configuration;

  constructor(
    public loadHistorySvc: CarrierLoadHistoryService,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  ngOnInit() {
    this.loading = true;
    this.loadHistorySvc.getLoadHistory(this.carrierCode).subscribe((data) => {
      this.loadHistoryGridData = data;
      this.loading = false;
    });
  }

  goToCarrierOffer(loadHistory: LoadHistoryDC) {
    window.open(
      `${this.config.backofficeShipmentEditUrl}/shipment-edit/${loadHistory.shipmentID}`,
    );
  }
}

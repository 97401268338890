import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';

import { RefService } from '../../../shared/references/ref.service';
import { CommonService } from '../../../Services/common.service';

import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { ServiceService } from './service.service';
import { RefServiceDC } from '../../../shared/references/RefServiceDC';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';
// import { SetRefreshCarrierCrmLeadStatusOn } from '../../actions/carrier-details.actions';

// credit: https://netbasal.com/handling-multiple-checkboxes-in-angular-forms-57eb8e846d21

@Component({
  selector: 'truckload-carrier-file-services',
  templateUrl: './carrier-file-services.component.html',
  styleUrls: ['./carrier-file-services.component.scss'],
})
export class CarrierFileServicesComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private serviceService: ServiceService,
    private refService: RefService,
    public commonService: CommonService,
    public store: Store<AppState>,
  ) {}

  @Input()
  BGCode: Observable<string>;

  private currentBGCode: string;

  public editMode = false;
  public options: any[];
  public serviceServices: RefServiceDC[];
  public formServices: FormGroup = new FormGroup({});

  get canEdit(): boolean {
    return this.authService.can(Permissions.EditServices);
  }

  ngOnInit() {
    this.BGCode.subscribe((data) => {
      this.currentBGCode = data;
      this.formServices = new FormGroup({});
      this.loadserviceServices();
    });
  }

  loadserviceServices(): void {
    this.serviceService.get(this.currentBGCode).subscribe(
      (data) => {
        this.serviceServices = data;
      },
      (err) => {},
      () => {
        this.editMode = false;
      },
    );
  }

  loadAllServices(): void {
    this.refService.getRefServices().subscribe((data) => {
      this.options = data.map((a) => {
        return {
          id: a.refServiceID,
          value: a.type,
          isChecked:
            this.serviceServices.filter(
              (b) => b.refServiceID === a.refServiceID,
            ).length > 0,
        };
      });

      this.formServices = this.fb.group({
        services: this.buildFormControls(),
      });
    });
  }

  buildFormControls() {
    const arr = this.options.map((svc) => {
      return this.fb.control(svc.isChecked);
    });
    return this.fb.array(arr);
  }

  get services() {
    return this.formServices && this.formServices.get('services');
  }

  edit(): void {
    this.loadAllServices();
    this.editMode = true;
  }

  save() {
    let serviceIDs = this.formServices.value.services
      .map((s, i) => {
        return s ? this.options[i].id : undefined;
      })
      .filter((a) => a !== undefined);

    this.serviceService
      .save(this.currentBGCode, serviceIDs)
      .subscribe((data) => {
        this.loadserviceServices();
        //this.store.dispatch(new SetRefreshCarrierCrmLeadStatusOn());
      });
  }

  cancel(): void {
    this.editMode = false;
  }
}

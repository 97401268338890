import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';

import { PortfolioRoutingModule } from './portfolio-routing.module';
import { CarrierModule } from '../carrier/carrier.module';
import { SharedModule } from '../shared/shared.module';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { PortfolioPageComponent } from './components/portfolio-page/portfolio-page.component';
import { PortfolioService } from './services/portfolio.service';
import { StoreModule } from '@ngrx/store';
import { appReducer } from '../app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from '../app.effects';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    GridModule,
    ButtonsModule,
    LayoutModule,
    PortfolioRoutingModule,
    CarrierModule,
    SharedModule,
    DialogModule,
    StoreModule.forFeature('Configuration', appReducer),
    EffectsModule.forFeature([AppEffects]),
  ],
  declarations: [PortfolioComponent, PortfolioPageComponent],
  providers: [PortfolioService],
})
export class PortfolioModule {}

import { Component, OnInit, ViewChild } from '@angular/core';
import { CarrierFile } from './CarrierFile';
import { ActivatedRoute, Router } from '@angular/router';
import { CarrierService } from '../../Services/carrier.service';
import { CommonService } from '../../Services/common.service';
import { BehaviorSubject } from 'rxjs';
import { CarrierErrors } from './CarrierErrors';
import { CarrierDetailsComponent } from './carrier-details/carrier-details.component';
import { CarrierAssociationComponent } from './carrier-association/carrier-association.component';
import { CarrierEditService } from '../services/carrier-edit-service';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstate.model';
import { LoadPreferredLanes } from '../actions/preferred-lanes.actions';
import { LoadCarrierDetails } from '../actions/carrier-details.actions';
import { LoadCarrierIdentity } from '../actions/carrier-identity.actions';
import { AuthService } from '../../authentication/_services/auth.service';
import { Permissions } from '../../shared/Enums/Permissions';

@Component({
  selector: 'truckload-carrier-file',
  templateUrl: './carrier-file.component.html',
  styleUrls: ['./carrier-file.component.scss'],
})
export class CarrierFileComponent implements OnInit {
  @ViewChild(CarrierDetailsComponent, { static: true })
  carrierDetails: CarrierDetailsComponent;
  @ViewChild(CarrierAssociationComponent, { static: true })
  carrierAssociation: CarrierAssociationComponent;

  carrierCode: string;
  outBGCode: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  carrierFile: CarrierFile;

  contactSaved: any;
  contactRemoved: any;

  errorMessages = [];
  isErrorWindowOpen = false;
  errorRedirect = false;

  constructor(
    public commonService: CommonService,
    public carrierService: CarrierService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private carrierEditService: CarrierEditService,
    private store: Store<AppState>,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.carrierCode = params['id'];
      if (!this.carrierCode) {
        this.router.navigate(['/carrier/search']);
      }
      this.store.dispatch(new LoadCarrierDetails(this.carrierCode));
      this.store.dispatch(new LoadCarrierIdentity(this.carrierCode));
      this.store.dispatch(new LoadPreferredLanes(this.carrierCode));
      this.loadCarrierFileDetails();
      this.carrierEditService.setup(this.carrierCode);
    });
  }

  loadCarrierFileDetails(): void {
    this.carrierService.getCarrierFileDetails(this.carrierCode).subscribe(
      (data) => {
        this.carrierFile = data;
        this.outBGCode.next(this.carrierCode);
      },
      (err) => {
        if (err.status === 404) {
          const error = {} as CarrierErrors;
          error.errors = [];
          error.errors.push(err.error);
          error.redirectToCarrierSearch = true;
          this.openErrorWindow(error);
        }
      },
    );
  }

  public getJumpLink(anchor: string) {
    const route = this.router.url.split('#')[0];
    return `${route}#${anchor}-a`;
  }

  openErrorWindow(errors: CarrierErrors) {
    this.errorMessages = errors.errors;
    this.isErrorWindowOpen = true;
    this.errorRedirect = errors.redirectToCarrierSearch;
  }

  closeErrorWindow() {
    if (this.errorRedirect) {
      this.router.navigate(['/carrier/search']);
    } else {
      this.isErrorWindowOpen = false;
      this.errorMessages = [];
    }
  }

  onIdentitiesSaved(event) {
    this.carrierDetails.onIdentitiesSaved(event);
  }

  onContactSaved(event) {
    this.carrierDetails.onContactSaved(event);
  }

  onStatusSaved(event) {
    this.carrierDetails.onStatusSaved(event);
  }

  onFollowingToggleChanged() {
    this.carrierAssociation.getAssociations(this.carrierCode);
  }

  get canViewCRMLeadStatus(): boolean {
    return this.authService.can(Permissions.ViewCRMLeadStatus);
  }

  get canViewLoadHistory(): boolean {
    return this.authService.can(Permissions.ViewLoadHistory);
  }
}

import { Action } from '@ngrx/store';
import { User } from 'oidc-client';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppUser } from '../_models/AppUser';

export const USER_AUTHENTICATION_SUCCESS =
  '[CARRIER] User Authentication Success';

export class UserAuthenticationSuccess implements Action {
  readonly type = USER_AUTHENTICATION_SUCCESS;
  constructor(
    public user: User,
    public config: Configuration,
  ) {}
}

export type Actions = UserAuthenticationSuccess;

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { User } from 'oidc-client';
import { Observable, Subject } from 'rxjs';
import { AppUser } from '../../authentication/_models/AppUser';
import { AppState } from '../../appstate.model';
import { AuthService } from '../../authentication/_services/auth.service';
import { Configuration } from '../configuration/Configuration';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  ldClient: LDClient;
  flags: LDFlagSet;
  flagChange: Subject<object> = new Subject<object>();
  config: Configuration;
  user: User;
  constructor() {}

  init(config: Configuration, user: User): Observable<LDFlagSet> {
    return new Observable<LDFlagSet>((observer) => {
      this.user = user;
      // const username = this.user.profile.name;
      // const useremail = this.user.profile.email;
      // const name = this.user.profile.name;

      const userInfo = {
        key: this.user.profile.name,
        email: this.user.profile.email,
        name: this.user.profile.name,
      };

      this.ldClient = initialize(`${config.launchDarklyKey}`, userInfo);

      this.ldClient.on('ready', () => {
        const flags = this.ldClient.allFlags();
        observer.next(flags);
      });

      this.ldClient.on('change', (flags) => {
        observer.next(flags);
      });
    });
  }
}

import { CarrierLaneSearchParameters } from '../models/CarrierLaneSearchParameters';

export class PreferredLaneLocationDTO {
  latitude: number;
  longitude: number;
  radius: number;
}

export class PreferredLaneSearchRequestDTO {
  origin: PreferredLaneLocationDTO;
  destination: PreferredLaneLocationDTO;
  truckTypeCodes: string[];

  constructor(params: CarrierLaneSearchParameters) {
    this.origin = {
      latitude: params.origin.latitude,
      longitude: params.origin.longitude,
      radius: params.odh,
    };

    this.destination = {
      latitude: params.destination.latitude,
      longitude: params.destination.longitude,
      radius: params.ddh,
    };

    this.truckTypeCodes = params.truckTypes;
  }
}

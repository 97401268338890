import { CarrierDetailsState } from '../models/carrier-details-state.model';
import * as CarrierDetailsActions from '../actions/carrier-details.actions';

var initialState: CarrierDetailsState = {
  loading: false,
  loadingError: null,
  details: null,
  status: null,
  updateLoading: false,
  updateError: null,
  crmLeadStatus: null,
  shouldRefreshCrmLeadStatus: false,
};

export function carrierDetailReducer(
  state: CarrierDetailsState = initialState,
  action: CarrierDetailsActions.Actions,
) {
  switch (action.type) {
    case CarrierDetailsActions.LOAD_CARRIER_DETAILS: {
      return { ...state, loading: true };
    }
    case CarrierDetailsActions.LOAD_CARRIER_DETAILS_SUCCESS: {
      return {
        ...state,
        details: action.state.details,
        status: action.state.status,
        crmLeadStatus: { status: action.state.details.crmLeadStatus },
        loading: false,
      };
    }
    case CarrierDetailsActions.LOAD_CARRIER_DETAILS_ERROR: {
      return { ...state, loading: false, error: action.errorMessage };
    }
    case CarrierDetailsActions.UPDATE_CARRIER_DETAILS: {
      return { ...state, loading: true, updateLoading: true };
    }
    case CarrierDetailsActions.UPDATE_CARRIER_DETAILS_SUCCESS: {
      var newDetails = {
        ...state.details,
        isAuthorizedCarrier: action.result.isAuthorizedCarrier,
      };
      return {
        ...state,
        loading: false,
        updateLoading: false,
        details: newDetails,
      };
    }
    case CarrierDetailsActions.UPDATE_CARRIER_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
        updateLoading: false,
        updateError: action.errorMessage,
      };
    }

    case CarrierDetailsActions.UPDATE_CARRIER_STATUS: {
      const crmLeadStatus = {
        ...state.crmLeadStatus,
        loading: true,
        error: '',
      };
      return {
        ...state,
        loading: true,
        updateLoading: true,
        status: state.status,
        crmLeadStatus,
      };
    }
    case CarrierDetailsActions.UPDATE_CARRIER_STATUS_SUCCESS: {
      const crmLeadStatus = {
        status: action.crmLeadStatus,
        loading: false,
        error: '',
      };
      return {
        ...state,
        loading: false,
        updateLoading: false,
        status: action.result,
        crmLeadStatus,
      };
    }
    case CarrierDetailsActions.UPDATE_CARRIER_STATUS_ERROR: {
      const crmLeadStatus = { loading: false };
      return {
        ...state,
        loading: false,
        updateLoading: false,
        updateError: action.errorMessage,
        crmLeadStatus,
      };
    }

    case CarrierDetailsActions.UPDATE_CARRIER_CRM_LEAD_STATUS: {
      return {
        ...state,
        crmLeadStatus: { ...state.crmLeadStatus, loading: true },
      };
    }
    case CarrierDetailsActions.UPDATE_CARRIER_CRM_LEAD_STATUS_SUCCESS: {
      const crmLeadStatus = {
        status: action.result,
        loading: false,
        error: '',
      };
      return { ...state, updateLoading: false, crmLeadStatus };
    }
    case CarrierDetailsActions.UPDATE_CARRIER_CRM_LEAD_STATUS_ERROR: {
      return {
        ...state,
        crmLeadStatus: {
          ...state.crmLeadStatus,
          loading: false,
          error: action.errorMessage,
        },
      };
    }

    case CarrierDetailsActions.SET_REFRESH_CARRIER_CRM_LEAD_STATUS_ON: {
      return { ...state, shouldRefreshCrmLeadStatus: true };
    }

    case CarrierDetailsActions.SET_REFRESH_CARRIER_CRM_LEAD_STATUS_OFF: {
      return { ...state, shouldRefreshCrmLeadStatus: false };
    }

    case CarrierDetailsActions.LOAD_CARRIER_CRM_LEAD_STATUS: {
      const crmLeadStatus = {
        ...state.crmLeadStatus,
        loading: true,
        error: '',
      };
      return {
        ...state,
        shouldRefreshCrmLeadStatus: false,
        crmLeadStatus: crmLeadStatus,
      };
    }
    case CarrierDetailsActions.LOAD_CARRIER_CRM_LEAD_STATUS_SUCCESS: {
      const crmLeadStatus = {
        status: action.crmLeadStatus,
        loading: false,
        error: '',
      };
      return { ...state, crmLeadStatus: crmLeadStatus };
    }
    case CarrierDetailsActions.LOAD_CARRIER_CRM_LEAD_STATUS_ERROR: {
      const crmLeadStatus = {
        ...state.details,
        crmLeadStatus: { loading: false, error: action.errorMessage },
      };
      return { ...state, crmLeadStatus: crmLeadStatus };
    }

    default:
      return state;
  }
}

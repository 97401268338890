import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CarrierIdentitySearchParameters } from '../../models/CarrierIdentitySearchParameters';
import { AppState } from '../../../appstate.model';
import * as SearchActions from '../../actions/search.actions';

@Component({
  selector: 'identity-search',
  templateUrl: 'identity.component.html',
  styleUrls: ['identity.component.scss'],
})
export class IdentityComponent implements OnInit {
  @ViewChild('identificationSearchForm', { static: true })
  identificationSearchForm: NgForm;
  carrierIdentitySearchParams: CarrierIdentitySearchParameters;

  constructor(private store: Store<AppState>) {
    this.store
      .select((x) => x.Search.carrierIdentitySearchParams)
      .subscribe((x) => {
        this.carrierIdentitySearchParams = x;
      });
  }

  ngOnInit(): void {}

  submitIdentificationSearch() {
    this.carrierIdentitySearchParams.CarrierIdentification =
      this.carrierIdentitySearchParams.CarrierIdentification.trim();
    this.store.dispatch(
      new SearchActions.SearchID(this.carrierIdentitySearchParams),
    );
  }
}

import { Configuration } from './shared/configuration/Configuration';
import * as AppActions from './app.actions';
const initialState: Configuration = {
  env: 'DEV',
  carrierServiceUrl: 'http://localhost:52865/api/', // 'https://carrier-api-qa.myblueship.com/api/',
  shipmentServiceUrl: 'https://shipmentapi-qa.myblueship.com/api/v1/',
  logApiUrl: 'http://blueship-api-log.azurewebsites.net',
  carrierOfferUrl: 'https://blueshipdev.myblueship.com/Truck/Index/',
  authUrl: 'https://identity-qa.myblueship.com/',
  authClientID: 'truckload',
  authRedirectURI: 'http://localhost:4200/auth-callback',
  authPostLogoutRedirectURI: 'http://localhost:4200',
  authResponseType: 'id_token token',
  authScope: 'openid profile truckload-api carrier-api',
  authFilterProtocolClaims: true,
  authLoadUserInfo: true,
  enableCarrierFile: true,
  enableManualCarrier: true,
  appInsights_InstrumentationKey: '',
  backofficeShipmentEditUrl: 'https://backoffice-staging.myblueship.com',
  launchDarklyKey: '5f74ba16cb0a9a0b66152038',
  disableCarrierSync: 'false',
};

export function appReducer(
  state: Configuration = initialState,
  action: AppActions.Actions,
) {
  switch (action.type) {
    case AppActions.LOAD_APP_CONFIG_SUCCESS: {
      return {
        ...state,
        env: action.config.env,
        carrierServiceUrl: action.config.carrierServiceUrl,
        shipmentServiceUrl: action.config.shipmentServiceUrl,
        logApiUrl: action.config.logApiUrl,
        carrierOfferUrl: action.config.carrierOfferUrl,
        authUrl: action.config.authUrl,
        authClientID: action.config.authClientID,
        authRedirectURI: action.config.authRedirectURI,
        authPostLogoutRedirectURI: action.config.authPostLogoutRedirectURI,
        authResponseType: action.config.authResponseType,
        authScope: action.config.authScope,
        authFilterProtocolClaims: action.config.authFilterProtocolClaims,
        authLoadUserInfo: action.config.authLoadUserInfo,
        enableCarrierFile: action.config.enableCarrierFile,
        enableManualCarrier: action.config.enableManualCarrier,
        appInsights_InstrumentationKey:
          action.config.appInsights_InstrumentationKey,
        backofficeShipmentEditUrl: action.config.backofficeShipmentEditUrl,
        launchDarklyKey: action.config.launchDarklyKey,
        disableCarrierSync: action.config.disableCarrierSync,
      };
    }
    default:
      return state;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierAddress } from './CarrierAddress';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';

@Injectable()
export class AddressService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getAddresses(carrierCode: string): Observable<CarrierAddress[]> {
    return this.http.get<CarrierAddress[]>(
      `${this.config.carrierServiceUrl}${carrierCode}/address`,
    );
  }

  insertUpdateCarrierAddress(
    address: CarrierAddress,
    carrierCode: string,
  ): Observable<CarrierAddress[]> {
    return this.http.post<CarrierAddress[]>(
      `${this.config.carrierServiceUrl}${carrierCode}/address`,
      address,
    );
  }

  deleteCarrierAddress(
    carrierCode: string,
    carrierAddressID: number,
  ): Observable<CarrierAddress[]> {
    return this.http.delete<CarrierAddress[]>(
      `${this.config.carrierServiceUrl}${carrierCode}/address/${carrierAddressID}`,
    );
  }
}

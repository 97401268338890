import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FollowCarrierToggle } from './FollowCarrierToggle';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstate.model';
import { Configuration } from '../configuration/Configuration';

@Injectable()
export class FollowToggleService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    store: Store<AppState>,
  ) {
    store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getFollowing(carrierCode: string): Observable<FollowCarrierToggle> {
    return this.http.get<FollowCarrierToggle>(
      `${this.config.carrierServiceUrl}${carrierCode}/userRelation/current/following`,
    );
  }

  follow(carrierCode: string): Observable<any> {
    return this.http.put<FollowCarrierToggle>(
      `${this.config.carrierServiceUrl}${carrierCode}/userRelation/current/follow`,
      null,
    );
  }

  unfollow(carrierCode: string): Observable<any> {
    return this.http.put<FollowCarrierToggle>(
      `${this.config.carrierServiceUrl}${carrierCode}/userRelation/current/unfollow`,
      null,
    );
  }

  deleteCarrierAssociation(
    carrierCode: string,
    employeeID: number,
  ): Observable<any> {
    return this.http.delete<any>(
      `${this.config.carrierServiceUrl}${carrierCode}/employeeRelation/${employeeID}`,
    );
  }
}

import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { CarrierOfferService } from './carrier-offer.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { UserDetails } from './UserDetails';
import { CarrierSummaryDC } from '../../carrier/dto/CarrierSummaryDC';
import { GetShipmentByPrimaryReferenceDC } from './GetShipmentByPrimaryReferenceDC';
import {
  ErrorTenderValidation,
  TenderValidation,
  TenderValidationTypes,
  WarningTenderValidation,
} from './TenderValidationResponse';

@Component({
  selector: 'truckload-carrier-offer',
  templateUrl: './carrier-offer.component.html',
  styleUrls: ['./carrier-offer.component.scss'],
})
export class CarrierOfferComponent extends DialogContentBase implements OnInit {
  // the match result passed in from post match component
  // tslint:disable-next-line:no-input-rename
  @Input('shipment-id') shipmentID: number;

  // tslint:disable-next-line:no-input-rename
  @Input('carrier-summary') carrierSummary: CarrierSummaryDC;

  // tslint:disable-next-line:no-input-rename
  @Input('carrierCode')
  carrierCode: string;

  @Input() public set currencyCode(currencyCode: string) {
    if (this.formCarrierOffer) {
      this.formCarrierOffer.controls.currencyCode.setValue(currencyCode);
    }
  }

  isValidating = false;
  tenderValidations: {
    errors: TenderValidation['message'][];
    hasErrors: boolean;
    hasWarnings: boolean;
    isValid: boolean;
    warnings: TenderValidation['message'][];
  } = {
    isValid: true,
    errors: [],
    warnings: [],
    get hasErrors() {
      return Boolean(this.errors && this.errors.length > 0);
    },
    get hasWarnings() {
      return Boolean(this.warnings && this.warnings.length > 0);
    },
  };

  public get buyRate(): AbstractControl {
    return this.formCarrierOffer ? this.formCarrierOffer.get('buyRate') : null;
  }

  public get quoteNote(): AbstractControl {
    return this.formCarrierOffer
      ? this.formCarrierOffer.get('quoteNote')
      : null;
  }

  public get userDetails(): Promise<UserDetails> {
    if (!this._userDetails) {
      this._userDetails = this.carrierOfferService.getUserDetails().toPromise();
    }

    return this._userDetails;
  }

  private get shipmentReference(): AbstractControl {
    return this.formCarrierOffer
      ? this.formCarrierOffer.get('shipmentReference')
      : null;
  }

  private customerCountryCode: string;
  private customerCurrencyCode: string;
  private requireShipmentReference: boolean;
  private _userDetails: Promise<UserDetails>;

  // reactive form for offer
  formCarrierOffer: FormGroup;

  public rateConversion = 1;
  showCurrency = false;

  private currencyCodes: string[] = ['USD', 'CAD'];

  constructor(
    public dialog: DialogRef,
    private carrierOfferService: CarrierOfferService,
  ) {
    super(dialog);
    this.requireShipmentReference = false;
  }

  ngOnInit(): void {
    this.formCarrierOffer = new FormGroup({
      shipmentReference: new FormControl(this.shipmentReference, {
        updateOn: 'blur',
        validators: [Validators.required],
      }),
      buyRate: new FormControl(this.buyRate, [Validators.required]),
      quoteNote: new FormControl(this.quoteNote, [Validators.required]),
      currencyCode: new FormControl(this.currencyCode),
    });

    this.requireShipmentReference = !this.shipmentID;
    this.buyRate.setValue(null);
    this.quoteNote.setValue(null);
    this.currencyCode = 'USD';

    if (!this.requireShipmentReference) {
      this.formCarrierOffer.get('shipmentReference').disable();
      this.carrierOfferService
        .getActiveShipment(this.shipmentID)
        .toPromise()
        .then((shipment) => {
          this.customerCountryCode = shipment.refCountryCode;
          this.customerCurrencyCode = shipment.refCurrencyCode;
        });
    }

    this.formCarrierOffer
      .get('shipmentReference')
      .setAsyncValidators(async (x) => {
        try {
          this.isValidating = true;
          return await this.validateShipmentReferenceField(x);
        } finally {
          this.isValidating = false;
        }
      });
  }

  private async validateShipmentReferenceField(
    x: AbstractControl,
  ): Promise<ValidationErrors | null> {
    let validationErrors: ValidationErrors = {};
    let userDetailsResponse: UserDetails;
    let findShipmentResponse: GetShipmentByPrimaryReferenceDC;

    try {
      userDetailsResponse = await this.userDetails;
      findShipmentResponse = await this.carrierOfferService
        .findShipment(x.value, userDetailsResponse)
        .toPromise();
      this.shipmentID = findShipmentResponse.shipmentID;
    } catch (error) {
      if (error.status === 404) {
        this.customerCountryCode = null;
        this.customerCurrencyCode = null;
        validationErrors.shipmentNotFound = true;
        return validationErrors;
      }
    }

    try {
      const [currencyCode, tenderValidations] = await Promise.all([
        this.carrierOfferService.getCustomerCurrencyCode(
          this.shipmentID,
          userDetailsResponse,
        ),
        this.carrierOfferService.validateShipmentCarrierTender(
          this.shipmentID,
          this.carrierSummary.carrierCode,
        ),
      ]);

      this.customerCurrencyCode = currencyCode;
      this.tenderValidations.isValid = tenderValidations.isValid;
      this.tenderValidations.errors = tenderValidations.validations
        .filter((val) => val.type === TenderValidationTypes.Error)
        .map((te) => te.message);
      this.tenderValidations.warnings = tenderValidations.validations
        .filter((val) => val.type === TenderValidationTypes.Warning)
        .map((tw) => tw.message);

      if (!this.tenderValidations.isValid) {
        validationErrors.tenderValidations = this.tenderValidations;
      }

      return validationErrors;
    } catch (error) {
      validationErrors.unknownError = true;
      return validationErrors;
    }
  }

  onCancelAction() {
    this.dialog.close({
      state: 'cancel',
    });
  }

  onConfirmAction() {
    let result = Object.assign({}, this.formCarrierOffer.value);

    result.shipmentID = this.shipmentID;

    this.dialog.close({
      state: 'submit',
      result: result,
    });
  }

  private showCurrencyDropdown(): boolean {
    return !!this.customerCurrencyCode && this.customerCurrencyCode !== 'USD';
  }

  public loadCarrierSummary() {
    return this.carrierOfferService.getCarrierSummary(this.carrierCode);
  }
}

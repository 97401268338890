import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { AuthService } from '../../authentication/_services/auth.service';
import { Permissions } from '../../shared/Enums/Permissions';
import { CarrierSearchParameters } from './../models/CarrierSearchParameters';
import { SearchState } from '../models/search.model';
import { RefService } from '../../shared/references/ref.service';
import { CarrierNameSearchParamters } from '../models/CarrierNameSearchParamters';
import { CarrierIdentitySearchParameters } from '../models/CarrierIdentitySearchParameters';
import { CarrierLocationSearchParameters } from '../models/CarrierLocationSearchParameters';
import { CarrierLaneSearchParameters } from '../models/CarrierLaneSearchParameters';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';
import { CarrierTruckPostingSearchParameters } from '../models/CarrierTruckPostingSearchParameters';
import { PageChangeEvent } from '@progress/kendo-angular-grid';

@Injectable()
export class SearchService {
  config: Configuration;
  constructor(
    private authService: AuthService,
    private refService: RefService,
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  get canSearchByID(): boolean {
    return this.authService.can(Permissions.ViewIDSearch);
  }

  get canSearchByName(): boolean {
    return this.authService.can(Permissions.ViewNameSearch);
  }

  get canSearchByLocation(): boolean {
    return this.authService.can(Permissions.ViewLocationSearch);
  }

  get canSearchByLane(): boolean {
    return this.authService.can(Permissions.ViewLaneSearch);
  }

  get canCreateManualCarrier(): boolean {
    return this.authService.can(Permissions.CreateManualCarrier);
  }

  get canViewPostMatch(): boolean {
    return this.authService.can(Permissions.ViewPostMatch);
  }

  get canSearchByTruckposting(): boolean {
    return this.authService.can(Permissions.ViewTruckPostingSearch);
  }
  // build initial state
  getState(): Observable<SearchState> {
    return new Observable<SearchState>((observer) => {
      const lastState = JSON.parse(
        localStorage.getItem('searchState'),
      ) as SearchState;
      if (lastState != null) {
        lastState.canSearchByName = this.canSearchByName;
        lastState.canSearchByID = this.canSearchByID;
        lastState.canSearchByLocation = this.canSearchByLocation;
        lastState.canSearchByLane = this.canSearchByLane;
        lastState.canCreateManualCarrier = this.canCreateManualCarrier;
        lastState.canViewPostMatch = this.canViewPostMatch;
        lastState.canSearchByTruckposting = this.canSearchByTruckposting;
        var lastSelectedSearch =
          lastState.carrierSearchParams == null
            ? 1
            : lastState.carrierSearchParams.SelectedId;
        lastState.carrierSearchParams = this.getParameters();
        lastState.carrierSearchParams.SelectedId = lastSelectedSearch;

        this.setSearchPaging(lastState);

        observer.next(lastState);
        observer.complete();
        return;
      }

      var truckTypes$ = this.refService.getTruckTypes();
      var serviceTypes$ = this.refService.getRefServices();
      var certificationTypes$ = this.refService.getCertifications();

      forkJoin([truckTypes$, serviceTypes$, certificationTypes$]).subscribe(
        (x) => {
          var newState = new SearchState();
          newState.carrierSearchParams = this.getParameters();
          newState.carrierNameSearchParams = new CarrierNameSearchParamters();
          newState.carrierIdentitySearchParams =
            new CarrierIdentitySearchParameters();
          newState.carrierLocationSearchParams =
            new CarrierLocationSearchParameters();
          newState.carrierLaneSearchParams = new CarrierLaneSearchParameters();
          newState.carrierTruckpostingSearchParams =
            new CarrierTruckPostingSearchParameters();
          newState.truckTypes = x[0].map((x) => x.code);
          newState.serviceTypes = x[1].map((x) => x.type);
          newState.certificationTypes = x[2].map((x) => x.type);
          newState.canSearchByName = this.canSearchByName;
          newState.canSearchByID = this.canSearchByID;
          newState.canSearchByLocation = this.canSearchByLocation;
          newState.canSearchByLane = this.canSearchByLane;
          newState.canSearchByTruckposting = this.canSearchByTruckposting;
          newState.canCreateManualCarrier = this.canCreateManualCarrier;
          newState.canViewPostMatch = this.canViewPostMatch;
          newState.carrierSearchResults = [];
          newState.carrierLaneSearchResults = [];
          newState.carrierTruckpostingSearchResults = [];
          newState.carrierGridSort = [];
          newState.carrierLaneGridSort = [];
          observer.next(newState);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        },
      );
    });
  }

  setSearchPaging(lastState: SearchState) {
    const carrierGridPaging = localStorage.getItem('carrierGridPaging');
    if (carrierGridPaging) {
      lastState.carrierGridPaging = JSON.parse(
        carrierGridPaging,
      ) as PageChangeEvent;
    }

    const carrierLaneGridPaging = localStorage.getItem('carrierLaneGridPaging');
    if (carrierLaneGridPaging) {
      lastState.carrierLaneGridPaging = JSON.parse(
        carrierLaneGridPaging,
      ) as PageChangeEvent;
    }

    const carrierTruckpostingGridPaging = localStorage.getItem(
      'carrierTruckpostingGridPaging',
    );
    if (carrierTruckpostingGridPaging) {
      lastState.carrierTruckpostingGridPaging = JSON.parse(
        carrierTruckpostingGridPaging,
      ) as PageChangeEvent;
    }
  }

  getParameters(): CarrierSearchParameters {
    var params: CarrierSearchParameters;

    if (!params) {
      params = {
        SearchSelections: [],
        SelectedId: 1,
      };

      params.SearchSelections.length = 0;

      if (this.canSearchByName) {
        params.SearchSelections.push({ Id: 1, Name: 'Carrier Name' });
      }
      if (this.canSearchByID) {
        params.SearchSelections.push({
          Id: 2,
          Name: 'DOT, Docket, State ID, SCAC, or BG Code',
        });
      }
      if (this.canSearchByLocation) {
        params.SearchSelections.push({ Id: 3, Name: 'Location' });
      }
      if (this.canSearchByLane) {
        params.SearchSelections.push({ Id: 4, Name: 'Preferred Lane' });
      }
      if (this.canSearchByTruckposting) {
        params.SearchSelections.push({ Id: 5, Name: 'TruckPosting' });
      }
    }

    return params;
  }

  getActiveShipment(shipmentId: number): Observable<any> {
    return this.http.get<any>(
      `${this.config.carrierServiceUrl}search/activeshipments/${shipmentId}`,
    );
  }
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, forkJoin } from 'rxjs';
import { State, process } from '@progress/kendo-data-query';
import {
  GridDataResult,
  DataStateChangeEvent,
  GridComponent,
  ColumnComponent,
} from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';

import { AuthService } from '../../../authentication/_services/auth.service';
import { RefService } from '../../../shared/references/ref.service';
import { notDefaultObjectValidator } from '../../../shared/validators/defaultValue.validator';
import { emailValidator } from '../../../shared/validators/email.validator';
import { Permissions } from '../../../shared/Enums/Permissions';
import {
  ContactService,
  InternalContactOriginationSource,
} from './contact.service';
import { RefContact } from '../../../shared/references/RefContact';
import {
  CarrierContact,
  CarrierContactVerificationStatus,
  CarrierContactVerificationStatusList,
} from './CarrierContact';
import { CarrierFileDetails } from '../../models/CarrierFileDetails';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { SortSettings } from '@progress/kendo-angular-grid/dist/es2015/columns/sort-settings';

type VerificationDialogData = {
  dataItem: CarrierContact;
  rowIndex: number;
};

@Component({
  selector: 'truckload-carrier-file-contacts',
  templateUrl: './carrier-file-contacts.component.html',
  styleUrls: ['./carrier-file-contacts.component.scss'],
  encapsulation: ViewEncapsulation.None, // Disabled for styling issues
})
export class CarrierFileContactsComponent implements OnInit {
  @Input()
  BGCode: Observable<string>;
  @Output()
  contactSaved: EventEmitter<any> = new EventEmitter();
  @Output()
  contactRemoved: EventEmitter<any> = new EventEmitter();
  @ViewChild('carrierContactsGrid', { static: false })
  grid: GridComponent;
  @ViewChild('sendVerificationDialog', { static: false })
  sendVerificationDialogTemplate: TemplateRef<any>;
  @ViewChild('resetVerificationDialog', { static: false })
  resetVerificationDialogTemplate: TemplateRef<any>;

  private currentBGCode: string;
  private editedRowIndex: number;
  private deletionCandidate: any;
  private dialogRef: DialogRef;

  carrierDetails: CarrierFileDetails;

  isLoading: boolean;
  originalData: CarrierContact[];
  gridData: GridDataResult;
  formContact: FormGroup;
  gridState: State = {
    skip: 0,
    take: 5,
    sort: [
      {
        field: 'isPrimary',
        dir: 'desc',
      },
      {
        field: 'verificationStatus',
        dir: 'desc',
      },
    ],
  };

  sortSettings = <SortSettings>{
    allowUnsort: true,
    mode: 'multiple',
  };

  contactVerificationStatusList = CarrierContactVerificationStatusList;
  contactTypeList: RefContact[];
  defaultRefContact: RefContact = { refContactID: 0, type: 'Select Type...' };
  deleteContactDialogOpened = false;
  verificationDialogData: VerificationDialogData;

  get canEditContacts(): boolean {
    return this.authService.can(Permissions.EditContacts);
  }

  get canEditComCheckAuthorization(): boolean {
    return this.authService.can(Permissions.EditComCheckAuthorization);
  }

  constructor(
    private authService: AuthService,
    private contactService: ContactService,
    private refService: RefService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private store: Store<AppState>,
  ) {
    store
      .select((x) => x.CarrierDetails.details)
      .subscribe((x) => {
        this.carrierDetails = x;
      });
  }

  ngOnInit() {
    this.isLoading = true;
    this.refService.getContactTypes().subscribe((data) => {
      this.contactTypeList = data;
    });

    this.BGCode.subscribe((data) => {
      this.currentBGCode = data;
      this.getContacts();
    });
  }

  private getContacts() {
    if (this.currentBGCode.length) {
      this.contactService.getContacts(this.currentBGCode).subscribe(
        (data) => {
          this.originalData = data;
          this.updateGridState();

          const primaryContact = data.find((x) => x.isPrimary);
          this.contactSaved.emit(primaryContact);
        },
        (error) => {
          // todo: add logging -rc
        },
        () => {
          this.isLoading = false;
        },
      );
    } else {
      this.gridData = null;
      this.isLoading = false;
    }
  }

  private updateGridState() {
    // Required manual calculations when externally editing Kendo Grid data.
    // Moves to new last page if last record is deleted from last page.
    const totalRecords = this.originalData.length;
    const { skip, take } = this.gridState;

    if (skip >= totalRecords) {
      this.gridState.skip = totalRecords - take;
    }

    this.gridData = process(this.originalData, this.gridState);
  }

  private updateContactGridData(updatedContact: CarrierContact) {
    const dataIdx = this.originalData.findIndex(
      (cc) => cc.carrierContactID === updatedContact.carrierContactID,
    );

    if (dataIdx !== -1) {
      this.originalData[dataIdx] = updatedContact;
      this.updateGridState();
    }
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formContact = undefined;
  }

  private async refreshContactById(
    carrierContactId: CarrierContact['carrierContactID'],
  ): Promise<void> {
    const updatedContact = await this.contactService
      .getContactById(this.carrierDetails.carrierCode, carrierContactId)
      .toPromise();

    this.updateContactGridData(updatedContact);
  }

  private createForm(carrierContact?: any): void {
    this.formContact = new FormGroup({
      carrierContactID: new FormControl(
        (carrierContact && carrierContact.carrierContactID) || 0,
      ),
      carrierID: new FormControl(
        (carrierContact && carrierContact.carrierID) || 0,
      ),
      contactID: new FormControl(
        (carrierContact && carrierContact.contactID) || 0,
      ),
      refContactID: new FormControl(
        (carrierContact && carrierContact.refContactID) || 0,
      ),
      name: new FormControl((carrierContact && carrierContact.name) || '', [
        Validators.required,
      ]),
      title: new FormControl((carrierContact && carrierContact.title) || ''),
      email: new FormControl(
        {
          value: (carrierContact && carrierContact.email) || '',
          disabled:
            carrierContact &&
            carrierContact.verificationStatus !==
              CarrierContactVerificationStatus.Unverified,
        },
        [emailValidator()],
      ),
      phone: new FormControl({
        value: (carrierContact && carrierContact.phone) || '',
        disabled:
          carrierContact &&
          carrierContact.verificationStatus !==
            CarrierContactVerificationStatus.Unverified,
      }),
      cell: new FormControl({
        value: (carrierContact && carrierContact.cell) || '',
        disabled:
          carrierContact &&
          carrierContact.verificationStatus !==
            CarrierContactVerificationStatus.Unverified,
      }),
      fax: new FormControl({
        value: (carrierContact && carrierContact.fax) || '',
        disabled:
          carrierContact &&
          carrierContact.verificationStatus !==
            CarrierContactVerificationStatus.Unverified,
      }),
      refContact: new FormControl(
        (carrierContact && carrierContact.contactType) ||
          this.defaultRefContact,
        [
          Validators.required,
          notDefaultObjectValidator(this.defaultRefContact),
        ],
      ),
      isPrimary: new FormControl(
        (carrierContact && carrierContact.isPrimary) || false,
      ),
      isAuthorizedContact: new FormControl(
        (carrierContact && carrierContact.isAuthorizedContact) || false,
      ),
      verificationStatus: new FormControl(
        carrierContact && carrierContact.verificationStatus,
      ),
    });

    if (carrierContact) {
      this.formContact.patchValue({
        refContact: {
          refContactID: carrierContact.refContactID,
          type: carrierContact.carrierType,
        },
      });
    }
  }

  canEditPrimaryContactStatus(dataItem: CarrierContact): boolean {
    return (
      (dataItem &&
        dataItem.verificationStatus &&
        dataItem.verificationStatus.toLowerCase() ===
          CarrierContactVerificationStatus.Verified.toLowerCase()) ||
      this.canEditContactVerification(dataItem)
    );
  }

  canEditContactVerification(dataItem?: CarrierContact): boolean {
    const hasAuth = this.authService.can(Permissions.EditContactVerification);
    return dataItem
      ? dataItem.carrierContactID > 0 &&
          this.isEditableCell(dataItem) &&
          hasAuth
      : hasAuth;
  }

  isEditableCell(dataItem: CarrierContact): boolean {
    // Third party data should never be editable directly by employees.
    return (
      !dataItem.originationSource ||
      dataItem.originationSource === InternalContactOriginationSource
    );
  }

  canDeleteContacts(dataItem: CarrierContact): boolean {
    return (
      this.authService.can(Permissions.DeleteCarrierContact) &&
      dataItem &&
      dataItem.originationSource === InternalContactOriginationSource
    );
  }

  onStateChange(state: DataStateChangeEvent) {
    this.gridState = state;
    this.updateGridState();
  }

  addHandler({ sender }) {
    this.closeEditor(sender);

    this.createForm();

    sender.addRow(this.formContact);
  }

  editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.createForm(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formContact);
  }

  cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const formValue = formGroup.value;
    const updatedContact = <CarrierContact>{
      carrierContactID: formValue.carrierContactID,
      carrierID: formValue.carrierID,
      contactID: formValue.contactID,
      refContactID: formValue.refContact.refContactID,
      contactType: formValue.refContact.type,
      isPrimary: formValue.isPrimary,
      name: formValue.name,
      title: formValue.title,
      // form control values are excluded from formGroup values when disabled
      phone: formValue.phone || formGroup.controls.phone.value,
      cell: formValue.cell || formGroup.controls.cell.value,
      fax: formValue.fax || formGroup.controls.fax.value,
      email: formValue.email || formGroup.controls.email.value,
      isAuthorizedContact: formValue.isAuthorizedContact,
    };

    if (updatedContact.isPrimary && !updatedContact.email) {
      this.notificationService.show({
        content: 'Cannot remove email address from Primary contact',
        cssClass: 'button-notification',
        animation: { type: 'fade', duration: 500 },
        position: { horizontal: 'center', vertical: 'bottom' },
        type: { style: 'error', icon: true },
      });
      sender.closeRow(rowIndex);
      return;
    }

    // Check if verificationStatus has changed and has permission to edit the value
    const isVerificationUpdate = Boolean(
      this.canEditContactVerification() &&
        formGroup.controls.verificationStatus.dirty,
    );

    const upsertContact$ = this.contactService.insertUpdateCarrierContact(
      this.currentBGCode,
      updatedContact,
    );

    const updateVerificationStatus$ = isVerificationUpdate
      ? this.contactService.overrideVerification(
          formValue.carrierContactID,
          formValue.verificationStatus,
        )
      : null;

    let requests$ = null;
    if (isVerificationUpdate) {
      requests$ = forkJoin([upsertContact$, updateVerificationStatus$]);
    } else {
      requests$ = upsertContact$;
    }

    requests$.subscribe(
      (result) => {
        let updateContactResult = null;

        if (isVerificationUpdate) {
          const [r1, r2] = result;
          updateContactResult = r1;
        } else {
          updateContactResult = result;
        }

        if (updateContactResult) {
          this.updateContactGridData(updateContactResult);
        }
      },
      (error) => {
        //todo: add logging - rc
      },
      () => {
        sender.closeRow(rowIndex);
        this.getContacts();
      },
    );
  }

  displayDialog(component: string, isDisplayed: boolean, dataItem?: any) {
    this[component + 'Opened'] = isDisplayed;
    if (dataItem) {
      this.deletionCandidate = dataItem;
    }
  }

  deleteContactDialogAction(status) {
    this.deleteContactDialogOpened = false;

    if (status === 'yes' && this.deletionCandidate) {
      this.contactService
        .deleteCarrierContact(
          this.currentBGCode,
          this.deletionCandidate.carrierContactID,
        )
        .subscribe(
          (result) => {
            this.originalData = this.originalData.filter(
              (di) =>
                di.carrierContactID != this.deletionCandidate.carrierContactID,
            );
            this.updateGridState();
          },
          (error) => {
            //todo: add logging - rc
          },
          () => {
            this.deletionCandidate = null;
            this.getContacts();
          },
        );
    }
  }

  canSendContactVerification(dataItem: CarrierContact): boolean {
    return (
      dataItem &&
      !dataItem.isPrimary && // primary contacts cannot verify themselves
      dataItem.verificationStatus &&
      dataItem.verificationStatus.toLowerCase() ===
        CarrierContactVerificationStatus.Unverified.toLowerCase() &&
      this.isEditableCell(dataItem)
    );
  }

  canResetContactVerification(dataItem: CarrierContact): boolean {
    return (
      dataItem &&
      !dataItem.isPrimary && // must prevent Primary contacts from invalid status
      dataItem.verificationStatus &&
      dataItem.verificationStatus.toLowerCase() !==
        CarrierContactVerificationStatus.Unverified.toLowerCase() &&
      this.isEditableCell(dataItem)
    );
  }

  isSendContactVerificationDisabled(dataItem: CarrierContact): boolean {
    const verifiedPrimaryContactExists = this.originalData.find(
      (c) =>
        c.isPrimary &&
        c.verificationStatus === CarrierContactVerificationStatus.Verified,
    );
    return !dataItem.email || !verifiedPrimaryContactExists;
  }

  /**
   * Contact Verification Dialogs
   */

  closeVerificationDialog(): void {
    this.dialogRef.close();
    this.cancelHandler({
      sender: this.grid,
      rowIndex: this.verificationDialogData.rowIndex,
    });
    this.verificationDialogData = null;
  }

  openSendVerificationDialog(dataItem: CarrierContact, rowIndex: number): void {
    this.verificationDialogData = { dataItem, rowIndex };

    this.dialogRef = this.dialogService.open({
      content: this.sendVerificationDialogTemplate,
      height: 200,
      width: 450,
    });
  }

  async sendVerificationConfirm(): Promise<void> {
    await this.contactService
      .sendVerification(
        this.carrierDetails.carrierCode,
        this.verificationDialogData.dataItem.carrierContactID,
      )
      .toPromise();
    await this.refreshContactById(
      this.verificationDialogData.dataItem.carrierContactID,
    );
    this.closeVerificationDialog();
  }

  openResetVerificationDialog(
    dataItem: CarrierContact,
    rowIndex: number,
  ): void {
    this.verificationDialogData = { dataItem, rowIndex };

    this.dialogRef = this.dialogService.open({
      content: this.resetVerificationDialogTemplate,
      height: 200,
      width: 450,
    });
  }

  async resetVerificationConfirm(): Promise<void> {
    await this.contactService
      .resetVerification(
        this.carrierDetails.carrierCode,
        this.verificationDialogData.dataItem.carrierContactID,
      )
      .toPromise();
    await this.refreshContactById(
      this.verificationDialogData.dataItem.carrierContactID,
    );
    this.closeVerificationDialog();
  }
}

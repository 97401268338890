import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  CarrierCertification,
  CarrierCertificationsModel,
} from './CarrierCertification';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';

@Injectable()
export class CertificationService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getCertifications(
    carrierCode: string,
  ): Observable<CarrierCertificationsModel> {
    return this.http.get<CarrierCertificationsModel>(
      `${this.config.carrierServiceUrl}${carrierCode}/certification`,
    );
  }

  saveCarrierCertifications(
    carrierCode: string,
    carrierCertifications: CarrierCertification[],
  ): Observable<number> {
    return this.http.post<number>(
      `${this.config.carrierServiceUrl}${carrierCode}/certification`,
      carrierCertifications,
    );
  }
}

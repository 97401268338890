import { RefAPILocation } from '../../shared/Models/RefAPILocation';

export class CarrierLaneSearchParameters {
  origin: RefAPILocation;
  destination: RefAPILocation;
  odh: number;
  ddh: number;
  truckTypes: string[];

  constructor() {
    this.origin = null;
    this.destination = null;
    this.odh = 0;
    this.ddh = 0;
    this.truckTypes = [];
  }
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './_services/auth.service';
import { UserService } from './_services/user.service';
import { PermissionService } from './_services/permission.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { LoggedInGuard } from '../shared/guards/auth/logged-in.guard';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { UserEnabledGuard } from '../shared/guards/auth/user-enabled.guard';
import { StoreModule } from '@ngrx/store';
import { appReducer } from '../app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from '../app.effects';

@NgModule({
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    StoreModule.forFeature('Configuration', appReducer),
    EffectsModule.forFeature([AppEffects]),
  ],
  declarations: [AuthCallbackComponent, NotAuthorizedComponent],
  providers: [
    PermissionService,
    UserService,
    AuthService,
    LoggedInGuard,
    UserEnabledGuard,
  ],
  exports: [AuthCallbackComponent],
})
export class AuthenticationModule {}

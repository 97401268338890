import { Action } from '@ngrx/store';
import { CarrierDetailsState } from '../models/carrier-details-state.model';
import { CarrierFileDetails } from '../models/CarrierFileDetails';
import { CarrierStatus } from '../models/CarrierStatus';

export const LOAD_CARRIER_DETAILS = '[CARRIER] Load Carrier Details';
export const LOAD_CARRIER_DETAILS_SUCCESS =
  '[CARRIER] Load Carrier Details Success';
export const LOAD_CARRIER_DETAILS_ERROR =
  '[CARRIER] Load Carrier Details Error';

export class LoadCarrierDetails implements Action {
  readonly type = LOAD_CARRIER_DETAILS;
  constructor(public carrierCode: string) {}
}

export class LoadCarrierDetailsSuccess implements Action {
  readonly type = LOAD_CARRIER_DETAILS_SUCCESS;
  constructor(public state: CarrierDetailsState) {}
}

export class LoadCarrierDetailsError implements Action {
  readonly type = LOAD_CARRIER_DETAILS_ERROR;
  constructor(public errorMessage: string) {}
}

export const UPDATE_CARRIER_DETAILS = '[CARRIER] Update Carrier Details';
export const UPDATE_CARRIER_DETAILS_SUCCESS =
  '[CARRIER] Update Carrier Details Success';
export const UPDATE_CARRIER_DETAILS_ERROR =
  '[CARRIER] Update Carrier Details Error';

export class UpdateCarrierDetails implements Action {
  readonly type = UPDATE_CARRIER_DETAILS;
  constructor(public details: CarrierFileDetails) {}
}

export class UpdateCarrierDetailsSuccess implements Action {
  readonly type = UPDATE_CARRIER_DETAILS_SUCCESS;
  constructor(public result: CarrierFileDetails) {}
}

export class UpdateCarrierDetailsError implements Action {
  readonly type = UPDATE_CARRIER_DETAILS_ERROR;
  constructor(public errorMessage: string) {}
}

export const UPDATE_CARRIER_STATUS = '[CARRIER] Update Carrier Status';
export const UPDATE_CARRIER_STATUS_SUCCESS =
  '[CARRIER] Update Carrier Status Success';
export const UPDATE_CARRIER_STATUS_ERROR =
  '[CARRIER] Update Carrier Status Error';

export class UpdateCarrierStatus implements Action {
  readonly type = UPDATE_CARRIER_STATUS;
  constructor(
    public carrierCode: string,
    public status: CarrierStatus,
  ) {}
}

export class UpdateCarrierStatusSuccess implements Action {
  readonly type = UPDATE_CARRIER_STATUS_SUCCESS;
  constructor(
    public result: CarrierStatus,
    public crmLeadStatus: string,
  ) {}
}

export class UpdateCarrierStatusError implements Action {
  readonly type = UPDATE_CARRIER_STATUS_ERROR;
  constructor(public errorMessage: string) {}
}

export const UPDATE_CARRIER_CRM_LEAD_STATUS =
  '[CARRIER] Update Carrier Crm Lead Status';
export const UPDATE_CARRIER_CRM_LEAD_STATUS_SUCCESS =
  '[CARRIER] Update Carrier Crm Lead Status Success';
export const UPDATE_CARRIER_CRM_LEAD_STATUS_ERROR =
  '[CARRIER] Update Carrier Crm Lead Status Error';

export class UpdateCarrierCrmLeadStatus implements Action {
  readonly type = UPDATE_CARRIER_CRM_LEAD_STATUS;
  constructor(
    public carrierCode: string,
    public status: string,
  ) {}
}

export class UpdateCarrierCrmLeadStatusSuccess implements Action {
  readonly type = UPDATE_CARRIER_CRM_LEAD_STATUS_SUCCESS;
  constructor(public result: string) {}
}

export class UpdateCarrierCrmLeadStatusError implements Action {
  readonly type = UPDATE_CARRIER_CRM_LEAD_STATUS_ERROR;
  constructor(public errorMessage: string) {}
}

export const SET_REFRESH_CARRIER_CRM_LEAD_STATUS_ON =
  '[CARRIER] Set flag in true to trigger a refresh for CrmLeadStatus';
export const SET_REFRESH_CARRIER_CRM_LEAD_STATUS_OFF =
  '[CARRIER] Set flag in false to trigger a refresh for CrmLeadStatus';

export class SetRefreshCarrierCrmLeadStatusOn implements Action {
  readonly type = SET_REFRESH_CARRIER_CRM_LEAD_STATUS_ON;
  constructor() {}
}

export class SetRefreshCarrierCrmLeadStatusOff implements Action {
  readonly type = SET_REFRESH_CARRIER_CRM_LEAD_STATUS_OFF;
  constructor() {}
}

export const LOAD_CARRIER_CRM_LEAD_STATUS =
  '[CARRIER] Load Carrier Crm Lead Status';
export const LOAD_CARRIER_CRM_LEAD_STATUS_SUCCESS =
  '[CARRIER] Load Carrier Crm Lead Status Success';
export const LOAD_CARRIER_CRM_LEAD_STATUS_ERROR =
  '[CARRIER] Load Carrier Crm Lead Status Error';

export class LoadCarrierCrmLeadStatus implements Action {
  readonly type = LOAD_CARRIER_CRM_LEAD_STATUS;
  constructor(public carrierCode: string) {}
}

export class LoadCarrierCrmLeadStatusSuccess implements Action {
  readonly type = LOAD_CARRIER_CRM_LEAD_STATUS_SUCCESS;
  constructor(public crmLeadStatus: string) {}
}

export class LoadCarrierCrmLeadStatusError implements Action {
  readonly type = LOAD_CARRIER_CRM_LEAD_STATUS_ERROR;
  constructor(public errorMessage: string) {}
}

export type Actions =
  | LoadCarrierDetails
  | LoadCarrierDetailsSuccess
  | LoadCarrierDetailsError
  | UpdateCarrierDetails
  | UpdateCarrierDetailsSuccess
  | UpdateCarrierDetailsError
  | UpdateCarrierStatus
  | UpdateCarrierStatusSuccess
  | UpdateCarrierStatusError
  | UpdateCarrierCrmLeadStatus
  | UpdateCarrierCrmLeadStatusSuccess
  | UpdateCarrierCrmLeadStatusError
  | LoadCarrierCrmLeadStatus
  | LoadCarrierCrmLeadStatusSuccess
  | LoadCarrierCrmLeadStatusError
  | SetRefreshCarrierCrmLeadStatusOn
  | SetRefreshCarrierCrmLeadStatusOff;

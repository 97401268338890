import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthService } from '../_services/auth.service';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  config: Configuration;
  constructor(
    public auth: AuthService,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.auth.isLoggedIn && !this.auth.isEnabled) {
      this.auth.redirectNotAuthorized();
    }

    // override request headers for anything other than shipping api
    if (request.url.indexOf(this.config.shipmentServiceUrl) == -1) {
      request = request.clone({
        setHeaders: {
          Authorization: this.auth.getAuthorizationHeaderValue(),
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.auth.startAuthentication(request.url);
            }
          }
        },
      ),
    );
  }
}
